import { AdminUserType } from "./AdminUserType"
import { DetailModelType } from "./DetailModelType"
import {
  Accessories,
  AccessoriesConditionCode,
  AttachmentType,
  ConditionsType,
  OfficialGuaranteeCode,
  OriginalBoxConditionCode,
  PriceRangeType,
  ProductConditionCode,
  UpgradeRequiredType,
} from "./DiagnosisType"
import { ResourceType } from "./ResourceType"
import { ImageLabelType } from "../pages/Diagnoses/AttachmentImageList"
import { SellOrderSaleChannelCode, SellOrderType } from "./SellOrderType"
import { BuyOrderType } from "./BuyOrderType"
import { BrandType } from "./BrandType"

export const enum CurrencyCode {
  KR = "KR",
}

export const enum ProductStatusCode {
  판매_대기 = "판매 대기",
  판매중 = "판매중",
  결제_대기 = "결제 대기",
  결제_완료 = "결제 완료",
  판매_완료 = "판매 완료",
  판매_취소 = "판매 취소",
}

export const enum SaleTypeCode {
  전시용 = "전시용",
  직접판매 = "직접판매",
  위탁판매 = "위탁판매",
  진단전판매 = "진단전판매",
  매입보증판매 = "매입보증판매",
}

export const enum UsedConditionLevelCode {
  새상품_수준 = "새상품 수준",
  사용감_있음 = "사용감 있음",
}

export const enum StabStatusCode {
  있음 = "있음",
  없음 = "없음",
}

export const enum GlassScratchStatusCode {
  있음 = "있음",
  없음 = "없음",
}

export const enum ProtectingFilmStatusCode {
  있음 = "있음",
  없음 = "없음",
}

export const enum BraceletLinksStatusCode {
  모두_있음 = "모두 있음",
  _1개_부족 = "1개 부족",
  _2개_부족 = "2개 부족",
  _3개_부족 = "3개 부족",
  _4개_이상_부족 = "4개 이상 부족",
  모름 = "모름",
  해당_없음 = "해당 없음",
}

export const enum DiagnosisTypeCode {
  일반 = "일반",
  정밀 = "정밀",
}

export const defaultImageLabels: ImageLabelType[] = [
  { label: "전면", required: true, uploadName: "1" },
  { label: "측면(크라운)", required: true, uploadName: "2" },
  { label: "후면(클라스프)", required: true, uploadName: "3" },
  { label: "측면(크라운 반대편)", required: true, uploadName: "4" },
  { label: "후면(뒷백)", required: true, uploadName: "5" },
  { label: "후면(전체)", required: true, uploadName: "6" },
  { label: "풀패키지샷", required: false, uploadName: "7" },
]

export declare interface SellerProvidedProductInfo {
  productCondition?: ProductConditionCode
  officialGuarantee?: OfficialGuaranteeCode
  accessoriesCondition?: AccessoriesConditionCode
  originalBoxCondition?: OriginalBoxConditionCode
  stampingYear?: number
  accessories?: Accessories
  selfCheck?: SelfCheckType
}

export declare interface ExposedProductInfo {
  productCondition?: ProductConditionCode
  officialGuarantee?: OfficialGuaranteeCode
  accessoriesCondition?: AccessoriesConditionCode
  originalBoxCondition?: OriginalBoxConditionCode
  stampingYear?: number
  stampingDate?: string
  accessories?: Accessories
  displayStampingMonth?: boolean
}

export declare interface SelfCheckType {
  stab?: StabStatusCode
  glassScratch?: GlassScratchStatusCode
  braceletLinks?: BraceletLinksStatusCode
  protectingFilm?: ProtectingFilmStatusCode
}

export declare interface ProductType {
  id: number
  priority?: number | null
  sellOrder?: SellOrderType
  buyOrder?: BuyOrderType

  sellerId?: number
  title?: string
  titleSecondary?: string
  thumbnail?: string
  thumbnailBackground?: string
  brand?: BrandType | null

  sellerProvidedProductInfo?: SellerProvidedProductInfo
  exposedProductInfo?: ExposedProductInfo

  serialNo?: string
  usedCondition?: UsedConditionLevelCode
  upgradeRequired: UpgradeRequiredType[]
  images: AttachmentType[]
  gallery?: ResourceType[]
  userDescription?: string
  appearanceCondition?: ConditionsType
  internalCondition?: ConditionsType
  generalReview?: string
  usedConditionNote?: string
  embeddedVideoUrl?: string

  productCondition?: ProductConditionCode | null
  stampingDate?: string

  location?: string
  estimatedPriceAtRegister?: PriceRangeType
  initialPrice?: number
  price?: number
  currency: CurrencyCode
  status: ProductStatusCode
  saleType: SaleTypeCode
  stock?: number
  onDisplay?: boolean
  repurchaseGuarantee?: boolean
  repurchaseGuaranteePeriod?: number
  repurchaseGuaranteeRate?: number

  brandSid?: string
  categorySid?: string
  detailModel: DetailModelType

  priceUpdatedAt?: string
  completeSetupAt?: string
  startSaleAt?: string
  completeSaleAt?: string
  createdAt?: string
  updatedAt?: string

  createdBy?: AdminUserType
  updatedBy?: AdminUserType
  priceDisparityRate?: number
  negotiatedAmount?: number
  saleChannel?: SellOrderSaleChannelCode
}

export declare interface ProductOnlyIdType {
  id: string
}

export declare interface ProductListSearchParams {
  id?: string
  title?: string
  fullRefNo?: string
  onDisplay?: Boolean
  status?: ProductStatusCode[]
  saleType?: SaleTypeCode[]
  startDate?: string
  endDate?: string

  sellOrderId?: string
  sellerId?: string
  sellerPhone?: string
  sellerName?: string

  buyOrderId?: string
  buyerId?: string
  buyerPhone?: string
  buyerName?: string

  page?: number
  size?: number
  sort?: string
}

export type ProductExcelParams = {
  startDate: String
  endDate: String
  saleTypes: SaleTypeCode[]
  status: ProductStatusCode[]
}
