import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { DateTime } from "luxon"
import React, { useCallback, useEffect, useState } from "react"
import {
  AttachmentType,
  DiagnosisGradeCode,
  DiagnosisResultCode,
  DiagnosisStatusCode,
  DiagnosisType,
  OfficialGuaranteeCode,
  PenaltyReasonTypeCode,
  ProductConditionCode,
  defaultImageLabels,
  OriginalBoxConditionCode,
} from "types/DiagnosisType"
import { complete, confirm, getDiagnosis, updateDiagnosis } from "../../../apis/diagnosisAPI"
import ProductQR from "../../../components/ProductQR"
import { SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import "../../../App.css"
import { floor, numberWithCommas, precisionRound, toRange, truncate } from "../../../utils/NumberUtils"
import { toast } from "react-toastify"
import { toDateStr } from "../../../utils/datetimeUtil"
import { getCancelSellOrderPenaltyFee, getResellPrice } from "../../../apis/estimationAPI"
import AttachmentImageList from "../AttachmentImageList"
import { ResellPriceResponse } from "types/EstimationType"
import { UserMemoTargetTypeCode } from "../../../types/UserType"
import QuickMemoButton from "../../../components/QuickMemo/QuickMemoButton"

import SellOrderInformation from "../EditPopup/SellOrderInformation"
import { SerialNumberFinder } from "components/SerialNumber/SerialNumberFinder"
import { getBrand } from "../../../apis/brandAPI"
import { BrandType } from "../../../types/BrandType"
import { getProduct, negotiatePrice } from "../../../apis/productAPI"
import { ProductType } from "../../../types/ProductType"
import { NegotiationPriceEditor } from "../NegotiationPriceEditor/NegotiationPriceEditor"
import { isNil } from "lodash"
import { isNotNil, validateEngDate } from "utils/validationUtils"
import AuthenticationIssuanceButton from "../../../components/AuthenticationIssuanceButton"

type EditBeforeDiagnosisDiagnosisPopupProps = {
  open: boolean
  selectedSellOrder: SellOrderType | undefined
  handleClose: Function
}

const EditBeforeDiagnosisDiagnosisPopup: React.FC<EditBeforeDiagnosisDiagnosisPopupProps> = ({
  open,
  selectedSellOrder,
  handleClose,
}) => {
  const [brand, setBrand] = useState<BrandType | undefined>()
  //@ts-ignore
  const [product, setProduct] = useState<ProductType>(selectedSellOrder?.product)
  //@ts-ignore
  const [diagnosis, setDiagnosis] = useState<DiagnosisType>(selectedSellOrder?.productDiagnosis)
  const [penalty, setPenalty] = useState<number>()
  const [estimatedPrice, setEstimatedPrice] = useState<ResellPriceResponse>({})
  const [saving, setSaving] = useState<boolean>(false)
  const [isEditingNegotiationPrice, setIsEditingNegotiationPrice] = useState<boolean>(false)
  const [formattedDate, setFormattedDate] = useState("")

  const formatDate = (date: Date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  const saveNegotiationPrice = useCallback(
    async (negotiatedAmount: number) => {
      if (isNil(selectedSellOrder) || isNil(selectedSellOrder.product) || isNil(selectedSellOrder.product.id)) return
      if (
        !window.confirm(
          `네고금액을 ${numberWithCommas(negotiatedAmount)}원으로 입력하시겠습니까?` +
            "\n네고금액을 입력한 후에는 수정이 불가능합니다."
        )
      )
        return

      try {
        await negotiatePrice(selectedSellOrder.product.id, negotiatedAmount)
        setProduct((prev) => ({ ...prev, negotiatedAmount }))
        setIsEditingNegotiationPrice(false)
        toast.success("네고금액을 저장했습니다.")
      } catch (err) {
        toast.error("네고금액 저장에 실패했습니다.")
      }
    },
    [selectedSellOrder]
  )

  const generateGeneralReview = (diagnosis: DiagnosisType) => {
    let review = ""

    switch (diagnosis?.result) {
      case "합격":
        review += "바이버 전문가의 진단 결과, 판매 가능한 '합격' 상품으로 확인되었습니다.\n"
        break
      case "조건부 합격":
        review +=
          "바이버 전문가의 진단 결과, 판매를 위해서는 반드시 업그레이드가 필요한 '조건부 합격' 상품으로 확인되었습니다.\n"
        break
      case "불합격":
        review +=
          "아쉬운 결과를 전해드려 대단히 죄송합니다. 바이버 전문가의 진단 결과, 판매가 불가능한 '불합격' 상품으로 확인되었습니다.\n"
        break
      default:
        break
    }
    switch (diagnosis?.grade) {
      case "양호":
        review +=
          "전반적으로 모든 상태가 양호하여 간단한 세척과 라이트폴리싱 후 바로 바이버 판매 상품으로 등록이 진행됩니다.\n"
        break
      case "보통":
        review +=
          "전반적으로 사용감은 있지만, 바이버 전문 엔지니어의 부분 폴리싱과 무상 수리를 통해 충분히 상품화가 가능한 상태입니다.\n"
        break
      case "업그레이드 필요":
        review +=
          "시계 본래의 기능인 정확성이 현저히 떨어지거나, 제품 전체의 광택이 저하되어 상품성이 낮아진 경우 해당 제품은 판매가 어렵습니다. 하지만 바이버의 숙련된 엔지니어를 통해 전문적인 오버홀, 폴리싱 작업을 거쳐 최상의 상태로 상품을 업그레이드하여 판매할 수 있습니다.\n"
        break
      case "판매 불가":
        review += `주요 판매 불가 사유는 다음과 같습니다.\n * ${diagnosis?.failedReason}\n`
        break
      default:
        break
    }
    if (diagnosis?.grade == "업그레이드 필요") {
      review += "고객님의 제품에 필요한 업그레이드(수리) 작업은 다음과 같습니다.\n"
      let fee = 0
      diagnosis?.upgradeRequired
        ?.filter((it) => it.required)
        ?.forEach((it) => {
          fee += it.fee
          if (it.note) review += `${it.note}\n`
          else
            switch (it.label) {
              case "폴리싱":
                review += " ＊폴리싱을 통해 시계의 광택을 살리고 스크레치를 개선합니다.\n"
                break
              case "오버홀":
                review +=
                  " ＊오버홀을 통해 시계 속 무브먼트를 완전 분해, 점검, 수리하고 조정하는 작업을 수행, 시계 내부를 최상의 상태로 개선합니다.\n"
                break
            }
        })
      review += `바이버 엔지니어가 산정한 업그레이드 비용은 (${numberWithCommas(fee)}원)입니다.\n`
    }
    if (diagnosis?.grade != "판매 불가") {
      if (!diagnosis?.components?.matched) {
        review += "패키지 상태 확인 결과, 작성해 주신 패키지 상태와 일부 차이가 있어 안내드립니다.\n"
        review += diagnosis?.components?.mismatchedReason || ""
      }
      review += diagnosis?.components?.note || ""
    }
    review += "\n진단을 통해 확인한 시계의 상세 상태는 내부/외부 검수항목 이미지를 참조해 주세요.\n"
    if (diagnosis?.grade != "양호") {
      diagnosis?.appearanceCondition?.parts?.forEach((it) => {
        review += ` * ${it.label}: ${it.grade}`
        if (it.note) review += `, ${it.note || ""}`
        review += "\n"
      })
      diagnosis?.internalCondition?.parts?.forEach((it) => {
        review += ` * ${it.label}: ${it.measured || ""} ${it.grade}`
        if (it.note) review += `, ${it.note || ""}`
        review += "\n"
      })
    }
    review += diagnosis?.viverPrices?.afterDiagnosisPrice?.reason || ""
    review += diagnosis?.viverPrices?.afterUpgradePrice?.reason || ""

    const price = Number(diagnosis?.viverPrices?.finalPrice || diagnosis?.userDesiredPrice)
    switch (diagnosis?.penaltyReasonType) {
      case PenaltyReasonTypeCode.가품:
        review += `바이버의 페널티 정책에 따라 예상 판매 금액 평균가 ${numberWithCommas(
          price
        )}원의 10%인 ${numberWithCommas(precisionRound(price * 0.1))}원이 고객님이 등록한 계좌에서 즉시 차감됩니다.\n`
        break
      case PenaltyReasonTypeCode.취급불가_커스텀:
        review += `바이버의 페널티 정책에 따라 예상 판매 금액 평균가 ${numberWithCommas(
          price
        )}원의 1.8%인 ${numberWithCommas(
          precisionRound(price * 0.018)
        )}원이 고객님이 등록한 계좌에서 즉시 차감됩니다.\n`
        break
      case PenaltyReasonTypeCode.부품_교체_필요:
        review +=
          "해당 상품은 정식 매장에서 부품을 교체해야 판매가 가능한 상품으로 별도의 페널티를 부여하지 않고 반송합니다. 부품을 교체하신 후 다시 바이버로 판매를 의뢰해 주세요.\n"
        break
      case PenaltyReasonTypeCode.상품화_불가:
        review +=
          "해당 상품은 정품이지만, 수리를 통한 업그레이드가 불가할 정도로 손상 상태가 심각하여, 바이버의 정책 상 판매가 불가능한 상품으로 판정되었습니다. 이러한 경우, 바이버에서는 별도의 페널티를 부여하지 않고 반송합니다.\n"
        break
      case PenaltyReasonTypeCode.상태_불일치:
        review += `바이버 랩스 진단 결과 고객께서 등록한 상품의 정보가 실제 입고된 상품의 상태와 일치하지 않아 불합격 처리되며 구매 거래가 취소되었습니다.\n\n*진단결과: ${
          diagnosis?.components?.mismatchedReason
        }\n\n바이버의 페널티 정책에 따라 예상 판매 금액 평균가 ${numberWithCommas(price)}원의 1.8%인 ${numberWithCommas(
          precisionRound(price * 0.018)
        )}원이 고객님이 등록한 계좌에서 즉시 차감됩니다.\n`
        break
    }
    switch (diagnosis?.result) {
      case DiagnosisResultCode.합격:
        review +=
          "진단 결과 확인 후 판매승인 하시면, 상품 세척 등 기본적인 케어와 상품촬영을 진행 한 후, 바이버 스토어에 판매 상품으로 노출됩니다. 상품이 등록되면 알림톡으로 알려드려요.\n"
        break
      case DiagnosisResultCode.조건부_합격:
        review +=
          "진단 결과 확인 후 판매승인 하시면, 바이버 업그레이드 진행 후 판매 상품으로 노출됩니다. 발생한 업그레이드 비용은 상품이 판매되면 최종 정산금액에서 차감됩니다.\n"
        break
      case DiagnosisResultCode.불합격:
        review += "해당 상품은 미납 페널티가 없음이 확인되면 2일 이내(주말,공휴일 제외) 반송 절차가 진행됩니다.\n"
        break
    }

    setDiagnosis({ ...diagnosis, generalReview: review })
    update({ ...diagnosis, generalReview: review })
  }

  const checkRequired = (): boolean => {
    if (!diagnosis?.serialNo) {
      toast.error("시리얼 넘버를 입력해주세요")
      return false
    }
    if (!diagnosis?.components?.staffConfirmed?.productCondition) {
      toast.error("제품상태를 선택해주세요")
      return false
    }
    if (!diagnosis?.components?.staffConfirmed?.officialGuarantee) {
      toast.error("보증서 유무를 선택해주세요")
      return false
    } /* else if (diagnosis?.components?.staffConfirmed?.officialGuarantee === OfficialGuaranteeCode.있음) {
      if (!diagnosis?.components?.staffConfirmed?.stampingDate) {
        toast.error("스탬핑 연월일을 입력해주세요")
        return false
      }
    }*/
    if (!diagnosis?.components?.staffConfirmed?.accessoriesCondition) {
      toast.error("부속품 상태를 선택해주세요")
      return false
    }

    if (!diagnosis?.components?.staffConfirmed?.originalBoxCondition) {
      toast.error("정품박스 상태를 선택해주세요")
      return false
    }

    if (diagnosis?.components?.matched == null) {
      toast.error("패키지 상태 일치여부를 선택해주세요")
      return false
    } else if (!diagnosis?.components?.matched && !diagnosis?.components?.mismatchedReason) {
      toast.error("패키지 상태 불일치 상세설명을 입력해주세요")
      return false
    }

    if (diagnosis?.appearanceCondition?.parts?.find((it) => it.grade == null)) {
      toast.error("외부 컨디션 항목별 상태를 모두 선택해주세요")
      return false
    }
    if (diagnosis?.internalCondition?.parts?.find((it) => it.grade == null)) {
      toast.error("내부 컨디션 항목별 상태를 모두 선택해주세요")
      return false
    }
    if (diagnosis?.internalCondition?.parts?.find((it) => !it.measured)) {
      toast.error("내부 컨디션 측정값을 모두 입력해주세요")
      return false
    }

    if (diagnosis?.grade == "판매 불가") {
      if (!diagnosis?.failedReason) toast.error("판매 불가 사유를 입력해주세요")
      // else if (!diagnosis?.penaltyReasonType) toast.error("판매 불가 페널티를 선택해주세요")
      else return true

      return false
    }
    if (defaultImageLabels?.find((required) => !diagnosis?.images?.find((it) => it.required && it.url))) {
      toast.error(`필수 이미지를 모두 첨부해주세요`)
      return false
    }
    if ((diagnosis?.images?.filter((it) => it.onDisplay == true)?.length || 0) < 1) {
      toast.error("판매자에게 노출할 이미지를 선택해주세요")
      return false
    }
    if (diagnosis?.status != DiagnosisStatusCode.진단서_작성중) {
      if (diagnosis?.result === null) {
        toast.error("진단 결과를 선택해주세요.")
        return false
      }
      if (!diagnosis?.generalReview?.length) {
        toast.error("진단 총평을 작성해주세요.")
        return false
      }
    }
    return true
  }

  const update = (diagnosis: DiagnosisType) => {
    if (diagnosis.components.staffConfirmed.officialGuarantee == "있음" && !formattedDate) {
      const stampingUnknownMessage =
        "보증서는 있음으로 설정됐으나, 스탬핑 일자가 입력되지 않았습니다.\n이 경우, 상품 상세에서 스탬핑 일자 ‘알 수 없음’으로 표기됩니다.\n계속하시겠습니까?"
      if (!window.confirm(stampingUnknownMessage)) {
        return Promise.reject(new Error("Stamping Unknown"))
      }
    }

    if (formattedDate && !validateEngDate(formattedDate)) {
      toast.error("스탬핑 일자를 확인해주세요. DD/MM/YYYY 형식으로 입력해야 합니다.")
      return Promise.reject(new Error("Invalid date format"))
    }
    diagnosis.components.staffConfirmed.stampingDate = formattedDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$2-$1")
    setSaving(true)
    return updateDiagnosis(diagnosis?.id, diagnosis)
      .then((res) => {
        setDiagnosis({ ...res, version: res.version + 1 })
        toast.success("저장 완료")
      })
      .catch((error) => {
        toast.error(`저장 실패 (${error.message})`)
      })
      .finally(() => setSaving(false))
  }

  const setComplete = async () => {
    update(diagnosis).then(() => {
      if (window.confirm("진단서 작성을 완료하시겠습니까?") && checkRequired()) {
        setSaving(true)
        // @ts-ignore
        complete(diagnosis?.id)
          .then((res) => {
            setDiagnosis({
              ...diagnosis,
              version: res.version + 1,
              status: DiagnosisStatusCode.진단서_작성_완료,
              diagnosedBy: res.diagnosedBy,
            })
            toast.success("진단서 작성 완료")
          })
          .catch((error) => {
            toast.error(`진단서 작성완료 처리 실패 (${error.message})`)
          })
          .finally(() => setSaving(false))
      }
    })
  }

  const setConfirm = () => {
    update(diagnosis).then(() => {
      if (window.confirm("진단서를 확정하시겠습니까?") && checkRequired()) {
        setSaving(true)
        // @ts-ignore
        confirm([diagnosis?.id])
          .then((res) => {
            const viverPrices = JSON.parse(JSON.stringify(diagnosis?.viverPrices))
            viverPrices!.estimatedPriceAtDiagnosis = estimatedPrice?.correctedEstimatedPrice

            setDiagnosis({
              ...diagnosis,
              status: DiagnosisStatusCode.진단서_확정,
              diagnosedBy: res.diagnosedBy,
              viverPrices: viverPrices,
            })
            toast.success("진단서 확정 완료")
          })
          .catch((err) => {
            toast.error(`진단서 확정 처리 실패 (${err.message})`)
          })
          .finally(() => setSaving(false))
      }
    })
  }

  const getCurrentResellPrice = (diagnosis: DiagnosisType) => {
    // 현 시세 사용
    const staffConfirmed = diagnosis.components?.staffConfirmed
    const dto = {
      detailModel: { id: selectedSellOrder?.product?.detailModel?.id! },
      mainProductCondition: staffConfirmed?.productCondition,
      accessoriesCondition: staffConfirmed?.accessoriesCondition,
      officialGuarantee: staffConfirmed?.officialGuarantee,
      stampingYear: staffConfirmed?.stampingDate ? parseInt(staffConfirmed?.stampingDate.substring(0, 4)) : undefined,
      originalBoxCondition: staffConfirmed.originalBoxCondition,
    }
    getResellPrice(dto).then((res) => {
      setEstimatedPrice(res)

      if (diagnosis.status != DiagnosisStatusCode.진단서_확정) {
        const max = res.correctedEstimatedPrice?.max
        const median = res.correctedEstimatedPrice?.median

        const viverPrices = JSON.parse(JSON.stringify(diagnosis?.viverPrices))

        viverPrices!.afterDiagnosisPrice!.before = median
        viverPrices!.afterDiagnosisPrice!.after = median
          ? median + viverPrices!.afterDiagnosisPrice!.delta
          : viverPrices!.afterDiagnosisPrice!.delta

        viverPrices!.afterUpgradePrice!.before = median
        viverPrices!.afterUpgradePrice!.after = median
          ? median + viverPrices!.afterUpgradePrice!.delta
          : viverPrices!.afterUpgradePrice!.delta

        viverPrices!.suggestedPrice =
          diagnosis?.result === "합격" ? viverPrices?.afterDiagnosisPrice?.after : viverPrices?.afterUpgradePrice?.after

        setDiagnosis({ ...diagnosis, viverPrices: viverPrices })
      }
    })
  }

  useEffect(() => {
    if (open) {
      getProduct(selectedSellOrder?.product?.id!).then((product) => {
        setProduct(product)
        getBrand(product?.brandSid || "ETC").then((brand) => {
          setBrand(brand)
        })
        if (product?.initialPrice)
          getCancelSellOrderPenaltyFee({ sellOrder: { id: selectedSellOrder?.id } }).then((penalty) => {
            setPenalty(penalty.paymentAmount)
          })
      })
      getDiagnosis(selectedSellOrder?.productDiagnosis?.id!).then((diagnosis) => {
        setDiagnosis(diagnosis)
        getCurrentResellPrice(diagnosis)
        if (diagnosis?.components?.staffConfirmed?.stampingDate) {
          const initialDate = new Date(diagnosis.components.staffConfirmed.stampingDate)
          setFormattedDate(formatDate(initialDate))
        }
      })
    } else {
      // @ts-ignore
      setDiagnosis({})
      setFormattedDate("")
      setBrand(undefined)
      setSaving(false)
      setIsEditingNegotiationPrice(false)
    }
  }, [open])

  return (
    <Dialog open={open} maxWidth={"xl"} fullWidth>
      <DialogTitle>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td rowSpan={2} className={"no-border ta-left"}>
                <ProductQR size={100} id={product?.id} />
              </td>
              <td colSpan={3} className={"no-border ta-left"}>
                <Typography fontWeight={"bold"} fontSize={"30px"}>
                  진단 체크리스트 (진단전판매)
                </Typography>
              </td>
              <td className={"header"}>상품번호</td>
              <td className={"header"}>담당자</td>
              <td className={"header"}>결과전달</td>
              <td className={"header"}>진단서 상태</td>
            </tr>
            <tr>
              <td colSpan={3} className={"no-border"}></td>
              <td>
                <a href={`/sell-orders/before-diagnosis?productId=${product?.id}`} target={"_blank"} rel="noreferrer">
                  {product?.id}
                </a>
                <QuickMemoButton target={UserMemoTargetTypeCode.PRODUCT} targetId={product?.id} small />
              </td>
              <td>{diagnosis?.diagnosedBy?.name}</td>
              <td>{!!diagnosis?.notifiedAt ? "완료" : "필요"}</td>
              <td className={"bold"}>{saving ? "저장중..." : diagnosis?.status}</td>
            </tr>
          </tbody>
        </table>
      </DialogTitle>
      <DialogContent>
        <SellOrderInformation sellOrder={selectedSellOrder} diagnosis={diagnosis} />

        <h2>상품상태</h2>
        <h3>고객입력</h3>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td className={"header"}>제품상태</td>
              <td className={"header"}>보증서</td>
              <td className={"header"}>정품박스</td>
              <td className={"header"}>부속품</td>
              <td className={"header"}>스탬핑 연도</td>
            </tr>
            <tr>
              <td>{product?.sellerProvidedProductInfo?.productCondition}</td>
              <td>{product?.sellerProvidedProductInfo?.officialGuarantee}</td>
              <td>{product?.sellerProvidedProductInfo?.originalBoxCondition}</td>
              <td>{product?.sellerProvidedProductInfo?.accessoriesCondition}</td>
              <td>{product?.sellerProvidedProductInfo?.stampingYear}</td>
            </tr>
            {product?.sellerProvidedProductInfo?.productCondition !== ProductConditionCode.미착용 && (
              <>
                <tr>
                  <td className={"header"} colSpan={3}>
                    상세 부속품
                  </td>
                  <td className={"header"} colSpan={2}>
                    자가진단
                  </td>
                </tr>
                <tr>
                  <td rowSpan={3} colSpan={3}>
                    {brand?.accessories?.map(({ key, value }) => (
                      <FormControlLabel
                        label={value}
                        style={{ marginRight: "30px" }}
                        disabled={true}
                        checked={!!product?.sellerProvidedProductInfo?.accessories?.[key]}
                        control={<Checkbox />}
                      />
                    ))}
                  </td>
                  <td>찍힘/눌림</td>
                  <td>{product?.sellerProvidedProductInfo?.selfCheck?.stab || "-"}</td>
                </tr>
                <tr>
                  <td>글라스 흠집</td>
                  <td>{product?.sellerProvidedProductInfo?.selfCheck?.glassScratch || "-"}</td>
                </tr>
                <tr>
                  <td>링크</td>
                  <td>{product?.sellerProvidedProductInfo?.selfCheck?.braceletLinks || "-"}</td>
                </tr>
              </>
            )}
            <tr>
              <td colSpan={5} className={"header"}>
                사진 ({product?.gallery?.length || 0} 개)
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                <div
                  style={{
                    display: "grid",
                    gap: "10px",
                    gridTemplateColumns: "auto auto auto auto",
                  }}
                >
                  {(product?.gallery || []).map((item, idx) => (
                    <div
                      style={{
                        position: "relative",
                        width: "300px",
                        height: "330px",
                        textAlign: "left",
                      }}
                    >
                      <p
                        style={{
                          position: "absolute",
                          right: "10px",
                          bottom: "10px",
                          background: "#00000088",
                          borderRadius: "20px",
                          padding: "0 8px",
                          margin: "0px",
                          fontSize: "12px",
                          color: "#FFF",
                        }}
                      >
                        {idx + 1} /{product?.gallery?.length}
                      </p>
                      <img
                        src={`${item.url}`}
                        srcSet={`${item.url}`}
                        alt={item.url}
                        loading="lazy"
                        style={{ objectFit: "contain", width: "300px", height: "300px", border: "1px solid #DDD" }}
                        onClick={() => {
                          window.open(`${item.url}`, "_blank")
                        }}
                      />
                    </div>
                  ))}
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={5} className={"header"}>
                설명 ({product?.userDescription?.length || 0} 자)
              </td>
            </tr>
            <tr>
              <td colSpan={5}>
                <TextField fullWidth multiline minRows={5} value={product?.userDescription} />
              </td>
            </tr>
          </tbody>
        </table>
        <h3>바이버체크</h3>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td className={"header"} colSpan={1} width={"15%"}>
                시리얼 넘버
              </td>
              <td className={"header"} width={"15%"}>
                제품상태
              </td>
              <td className={"header"} width={"15%"}>
                보증서
              </td>
              <td className={"header"} width={"15%"}>
                정품박스
              </td>
              <td className={"header"} width={"20%"}>
                부속품
              </td>
              <td className={"header"}>스탬핑 일자</td>
            </tr>
            <tr>
              <td colSpan={1}>
                <div style={{ margin: "0 10px" }}>
                  <SerialNumberFinder
                    value={diagnosis.serialNo || ""}
                    onChangeValue={(value) => setDiagnosis({ ...diagnosis, serialNo: value })}
                    onSelect={(serial) => setDiagnosis({ ...diagnosis, serialNo: serial?.serialNo })}
                  />
                </div>
              </td>
              <td colSpan={1}>
                <RadioGroup
                  row
                  sx={{ justifyContent: "center" }}
                  onChange={(event, value) => {
                    const components = JSON.parse(JSON.stringify(diagnosis?.components))
                    components!.staffConfirmed!.productCondition = value
                    // @ts-ignore
                    setDiagnosis({ ...diagnosis, components: components })
                    getCurrentResellPrice({ ...diagnosis, components: components })
                  }}
                >
                  <FormControlLabel
                    value="미착용"
                    label="미착용"
                    checked={diagnosis?.components?.staffConfirmed?.productCondition === "미착용"}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value="중고"
                    label="중고"
                    checked={diagnosis?.components?.staffConfirmed?.productCondition === "중고"}
                    control={<Radio />}
                  />
                </RadioGroup>
              </td>
              <td colSpan={1}>
                <RadioGroup
                  row
                  sx={{ justifyContent: "center" }}
                  onChange={(event, value) => {
                    const components = JSON.parse(JSON.stringify(diagnosis?.components))
                    components!.staffConfirmed!.officialGuarantee = value

                    if (value == "없음") {
                      components!.staffConfirmed.stampingDate = undefined
                      components!.staffConfirmed.onDisplayStampingYear = false
                    }
                    // @ts-ignore
                    setDiagnosis({ ...diagnosis, components: components })
                    // @ts-ignore
                    getCurrentResellPrice({ ...diagnosis, components: components })
                  }}
                >
                  <FormControlLabel
                    value="있음"
                    label="있음"
                    checked={diagnosis?.components?.staffConfirmed?.officialGuarantee === "있음"}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value="없음"
                    label="없음"
                    checked={diagnosis?.components?.staffConfirmed?.officialGuarantee === "없음"}
                    control={<Radio />}
                  />
                </RadioGroup>
              </td>
              <td colSpan={1}>
                <RadioGroup
                  row
                  sx={{ justifyContent: "center" }}
                  onChange={(event, value) => {
                    const components = JSON.parse(JSON.stringify(diagnosis?.components))
                    components!.staffConfirmed!.originalBoxCondition = value
                    // @ts-ignore
                    setDiagnosis({ ...diagnosis, components: components })
                    getCurrentResellPrice({ ...diagnosis, components: components })
                  }}
                >
                  <FormControlLabel
                    value={OriginalBoxConditionCode.있음}
                    label="있음"
                    checked={
                      diagnosis?.components?.staffConfirmed?.originalBoxCondition === OriginalBoxConditionCode.있음
                    }
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value={OriginalBoxConditionCode.없음}
                    label="없음"
                    checked={
                      diagnosis?.components?.staffConfirmed?.originalBoxCondition === OriginalBoxConditionCode.없음
                    }
                    control={<Radio />}
                  />
                </RadioGroup>
              </td>
              <td colSpan={1}>
                <RadioGroup
                  row
                  sx={{ justifyContent: "center" }}
                  onChange={(event, value) => {
                    const components = JSON.parse(JSON.stringify(diagnosis?.components))

                    components!.staffConfirmed!.accessoriesCondition = value
                    // @ts-ignore
                    setDiagnosis({ ...diagnosis, components: components })
                    getCurrentResellPrice({ ...diagnosis, components: components })
                  }}
                >
                  <FormControlLabel
                    value="있음"
                    label="있음"
                    checked={diagnosis?.components?.staffConfirmed?.accessoriesCondition == "있음"}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value="없음"
                    label="없음"
                    checked={diagnosis?.components?.staffConfirmed?.accessoriesCondition == "없음"}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value="일부파손"
                    label="일부파손"
                    checked={diagnosis?.components?.staffConfirmed?.accessoriesCondition == "일부파손"}
                    control={<Radio />}
                  />
                </RadioGroup>
              </td>
              <td colSpan={1}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  <TextField
                    variant="standard"
                    style={{ width: "50%" }}
                    inputProps={{ maxLength: 10 }}
                    type={"text"}
                    placeholder={"DD/MM/YYYY"}
                    disabled={diagnosis?.components?.staffConfirmed?.officialGuarantee == "없음"}
                    value={formattedDate}
                    onChange={(event) => {
                      const components = JSON.parse(JSON.stringify(diagnosis?.components))
                      components!.staffConfirmed!.stampingDate = event.target.value

                      // @ts-ignore
                      setDiagnosis({ ...diagnosis, components: components })
                      getCurrentResellPrice({ ...diagnosis, components: components })
                      setFormattedDate(event.target.value)
                    }}
                  />
                  <FormControlLabel
                    value={"연도만 표기"}
                    label={"연도만 표기"}
                    disabled={diagnosis?.components?.staffConfirmed?.officialGuarantee == "없음"}
                    checked={diagnosis?.components?.staffConfirmed?.onDisplayStampingYear ?? false}
                    control={<Checkbox />}
                    onChange={(event, value) => {
                      const components = JSON.parse(JSON.stringify(diagnosis?.components))
                      components!.staffConfirmed!.onDisplayStampingYear = value

                      // @ts-ignore
                      setDiagnosis({ ...diagnosis, components: components })
                    }}
                  />
                </div>
                <RadioGroup
                  row
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography style={{ marginLeft: 10 }}>정식 A/S기간&nbsp;</Typography>
                  <div style={{ marginRight: 10 }}>
                    <FormControlLabel
                      label="내"
                      checked={
                        formattedDate
                          ? DateTime.fromFormat(formattedDate, "dd/MM/yyyy").diffNow("years").years >= -5
                          : false
                      }
                      control={<Radio color={"default"} />}
                    />
                    <FormControlLabel
                      label="외"
                      checked={
                        formattedDate
                          ? DateTime.fromFormat(formattedDate, "dd/MM/yyyy").diffNow("years").years < -5
                          : false
                      }
                      control={<Radio color={"default"} />}
                    />
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td className={"header"} colSpan={4}>
                상세 부속품 체크리스트
                <br />
                <span style={{ fontWeight: 400, fontSize: "10pt", color: "#777" }}>(있는 부속품만 체크해주세요)</span>
              </td>
              <td className={"header"} colSpan={2}>
                특이사항
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                {brand?.accessories?.map(({ key, value }) => (
                  <FormControlLabel
                    label={value}
                    checked={!!diagnosis?.components?.staffConfirmed?.accessories?.[key]}
                    control={<Checkbox />}
                    onChange={(event, value) => {
                      const components = JSON.parse(JSON.stringify(diagnosis?.components))
                      components!.staffConfirmed!.accessories![key] = value

                      // @ts-ignore
                      setDiagnosis({ ...diagnosis, components: components })
                      getCurrentResellPrice({ ...diagnosis, components: components })
                    }}
                  />
                ))}
              </td>
              <td colSpan={2}>
                <TextField
                  variant="standard"
                  type={"text"}
                  fullWidth={true}
                  multiline={true}
                  value={diagnosis?.components?.staffConfirmed?.note}
                  onChange={(event) => {
                    const components = JSON.parse(JSON.stringify(diagnosis?.components))
                    components!.staffConfirmed!.note = event.target.value

                    // @ts-ignore
                    setDiagnosis({ ...diagnosis, components: components })
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <h3>진단 후 패키지 상태 일치 여부 (고객이 입력한 상태와 바이버 진단 상태)</h3>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td colSpan={2} className={"header"}>
                바이버 진단결과
              </td>
              <td colSpan={3} className={"header"}>
                불일치 시 상세 설명
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <RadioGroup
                  row
                  sx={{ justifyContent: "center" }}
                  onChange={(event, value) => {
                    const components = JSON.parse(JSON.stringify(diagnosis?.components))
                    components!.matched = value == "true"
                    setDiagnosis({ ...diagnosis, components: components })
                  }}
                >
                  <FormControlLabel
                    value={"true"}
                    label="일치"
                    checked={diagnosis?.components?.matched == true}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value={"false"}
                    label="불일치"
                    checked={diagnosis?.components?.matched == false}
                    control={<Radio />}
                  />
                </RadioGroup>
              </td>
              <td colSpan={3} className={"ta-left"}>
                <TextField
                  variant="standard"
                  fullWidth={true}
                  type={"text"}
                  multiline={true}
                  value={diagnosis?.components?.mismatchedReason}
                  onChange={(event) => {
                    const components = JSON.parse(JSON.stringify(diagnosis?.components))
                    components!.mismatchedReason = event.target.value
                    setDiagnosis({ ...diagnosis, components: components })
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={5} className={"header"}>
                진단자 메모
              </td>
            </tr>
            <tr>
              <td colSpan={5} className={"ta-left"}>
                <TextField
                  variant="standard"
                  fullWidth={true}
                  type={"text"}
                  multiline={true}
                  value={diagnosis?.components?.note}
                  onChange={(event) => {
                    const components = JSON.parse(JSON.stringify(diagnosis?.components))
                    components!.note = event.target.value
                    setDiagnosis({ ...diagnosis, components: components })
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <h2>상세진단</h2>
        <div>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>외부 컨디션</AccordionSummary>
            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <RadioGroup
                row
                sx={{ justifyContent: "center" }}
                onChange={(event, value) => {
                  if (value) {
                    diagnosis?.appearanceCondition?.parts?.forEach((it) => {
                      it.grade = DiagnosisGradeCode.양호
                    })
                    setDiagnosis({ ...diagnosis })
                  }
                }}
              >
                <FormControlLabel
                  value="전체 양호"
                  label="전체 양호"
                  checked={diagnosis?.appearanceCondition?.parts?.every((it, idx) => {
                    return it.grade === DiagnosisGradeCode.양호
                  })}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
            <AccordionDetails>
              <table className={"basic"}>
                <tbody>
                  <tr>
                    <td className={"header"}>상세항목</td>
                    <td colSpan={3} className={"header"}>
                      항목별 상태
                    </td>
                    <td className={"header"}>상세설명</td>
                    <td className={"header"}>사진필요</td>
                  </tr>
                  {diagnosis?.appearanceCondition?.parts?.map((it, idx) => (
                    <tr>
                      <td>{it.label}</td>
                      <td colSpan={3}>
                        <RadioGroup
                          row
                          sx={{ justifyContent: "center" }}
                          onChange={(event, value) => {
                            const appearanceCondition = JSON.parse(JSON.stringify(diagnosis?.appearanceCondition))
                            appearanceCondition!.parts[idx]!.grade = value
                            setDiagnosis({ ...diagnosis, appearanceCondition: appearanceCondition })
                          }}
                        >
                          <FormControlLabel
                            value="양호"
                            label="양호"
                            checked={diagnosis?.appearanceCondition?.parts[idx]!.grade == DiagnosisGradeCode.양호}
                            control={<Radio />}
                          />
                          <FormControlLabel
                            value="보통"
                            label="보통"
                            checked={diagnosis?.appearanceCondition?.parts[idx]!.grade == DiagnosisGradeCode.보통}
                            control={<Radio />}
                          />
                          <FormControlLabel
                            value="업그레이드 필요"
                            label="업그레이드 필요"
                            checked={
                              diagnosis?.appearanceCondition?.parts[idx]!.grade == DiagnosisGradeCode.업그레이드_필요
                            }
                            control={<Radio />}
                          />
                          <FormControlLabel
                            value="판매 불가"
                            label="판매 불가"
                            checked={diagnosis?.appearanceCondition?.parts[idx]!.grade == DiagnosisGradeCode.판매_불가}
                            control={<Radio />}
                          />
                        </RadioGroup>
                      </td>
                      <td>
                        <TextField
                          variant="standard"
                          fullWidth={true}
                          type={"text"}
                          multiline={true}
                          value={diagnosis?.appearanceCondition?.parts[idx]!.note}
                          onChange={(event) => {
                            const appearanceCondition = JSON.parse(JSON.stringify(diagnosis?.appearanceCondition))
                            appearanceCondition!.parts[idx]!.note = event.target.value
                            setDiagnosis({ ...diagnosis, appearanceCondition: appearanceCondition })
                          }}
                        />
                      </td>
                      <td>
                        <Checkbox
                          checked={diagnosis?.appearanceCondition?.parts[idx]!.imageRequired}
                          onChange={(event, value) => {
                            const appearanceCondition = JSON.parse(JSON.stringify(diagnosis?.appearanceCondition))
                            appearanceCondition!.parts[idx]!.imageRequired = value
                            setDiagnosis({ ...diagnosis, appearanceCondition: appearanceCondition })
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={6} className={"header"}>
                      진단자 메모
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      <TextareaAutosize
                        style={{ width: "100%" }}
                        minRows={3}
                        value={diagnosis?.appearanceCondition?.note}
                        onChange={(event) => {
                          const appearanceCondition = JSON.parse(JSON.stringify(diagnosis?.appearanceCondition))
                          appearanceCondition!.note = event.target.value
                          // @ts-ignore
                          setDiagnosis({ ...diagnosis, appearanceCondition: appearanceCondition })
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              {/*
                        <div style={{textAlign: "right"}}>
                            <Button size={"large"} disabled={saving || diagnosis?.status==='진단서 확정'} variant={"contained"} onClick={updateAppearanceCondition}>외부컨디션 변경내역 저장하기</Button>
                        </div>
*/}
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>내부 컨디션</AccordionSummary>
            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <RadioGroup
                row
                sx={{ justifyContent: "center" }}
                onChange={(event, value) => {
                  if (value) {
                    diagnosis?.internalCondition?.parts?.forEach((it) => {
                      it.grade = DiagnosisGradeCode.양호
                    })
                    setDiagnosis({ ...diagnosis })
                  }
                }}
              >
                <FormControlLabel
                  value="전체 양호"
                  label="전체 양호"
                  checked={diagnosis?.internalCondition?.parts?.every((it, idx) => {
                    return it.grade === DiagnosisGradeCode.양호
                  })}
                  control={<Radio />}
                />
              </RadioGroup>
            </div>
            <AccordionDetails>
              <table className={"basic"}>
                <tbody>
                  <tr>
                    <td className={"header"}>상세항목</td>
                    <td colSpan={3} className={"header"}>
                      항목별 상태
                    </td>
                    <td className={"header"}>측정값</td>
                    <td className={"header"}>상세설명</td>
                  </tr>
                  {diagnosis?.internalCondition?.parts?.map((it, idx) => (
                    <tr>
                      <td>{it.label}</td>
                      <td colSpan={3}>
                        <RadioGroup
                          row
                          sx={{ justifyContent: "center" }}
                          onChange={(event, value) => {
                            const internalCondition = JSON.parse(JSON.stringify(diagnosis?.internalCondition))
                            internalCondition!.parts[idx]!.grade = event.target.value
                            setDiagnosis({ ...diagnosis, internalCondition: internalCondition })
                          }}
                        >
                          <FormControlLabel
                            value="양호"
                            label="양호"
                            checked={diagnosis?.internalCondition?.parts[idx]!.grade == DiagnosisGradeCode.양호}
                            control={<Radio />}
                          />
                          <FormControlLabel
                            value="보통"
                            label="보통"
                            checked={diagnosis?.internalCondition?.parts[idx]!.grade == DiagnosisGradeCode.보통}
                            control={<Radio />}
                          />
                          <FormControlLabel
                            value="업그레이드 필요"
                            label="업그레이드 필요"
                            checked={
                              diagnosis?.internalCondition?.parts[idx]!.grade == DiagnosisGradeCode.업그레이드_필요
                            }
                            control={<Radio />}
                          />
                          <FormControlLabel
                            value="판매 불가"
                            label="판매 불가"
                            checked={diagnosis?.internalCondition?.parts[idx]!.grade == DiagnosisGradeCode.판매_불가}
                            control={<Radio />}
                          />
                        </RadioGroup>
                      </td>
                      <td>
                        <TextField
                          variant="standard"
                          type={"text"}
                          value={diagnosis?.internalCondition?.parts[idx]!.measured}
                          onChange={(event) => {
                            const internalCondition = JSON.parse(JSON.stringify(diagnosis?.internalCondition))
                            internalCondition!.parts[idx]!.measured = event.target.value
                            setDiagnosis({ ...diagnosis, internalCondition: internalCondition })
                          }}
                        />
                      </td>
                      <td>
                        <TextField
                          variant="standard"
                          fullWidth={true}
                          type={"text"}
                          multiline={true}
                          value={diagnosis?.internalCondition?.parts[idx]!.note}
                          onChange={(event) => {
                            const internalCondition = JSON.parse(JSON.stringify(diagnosis?.internalCondition))
                            internalCondition!.parts[idx]!.note = event.target.value
                            setDiagnosis({ ...diagnosis, internalCondition: internalCondition })
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={6} className={"header"}>
                      진단자 메모
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={6}>
                      <TextareaAutosize
                        style={{ width: "100%" }}
                        minRows={3}
                        value={diagnosis?.internalCondition?.note}
                        onChange={(event) => {
                          // @ts-ignore
                          const internalCondition = JSON.parse(JSON.stringify(diagnosis?.internalCondition))
                          internalCondition!.note = event.target.value
                          setDiagnosis({ ...diagnosis, internalCondition: internalCondition })
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </AccordionDetails>
          </Accordion>
          <h2>업그레이드</h2>
          <table className={"basic"}>
            <tbody>
              <tr>
                <td className={"header"}>수리 종류</td>
                <td className={"header"} colSpan={2}>
                  필요 여부
                </td>
                <td className={"header"}>수리 비용</td>
                <td className={"header"} colSpan={2}>
                  특이사항 (고객 고지 필요사항)
                </td>
              </tr>
              {diagnosis?.upgradeRequired?.map((it, idx) => (
                <tr>
                  <td>{it.label}</td>
                  <td colSpan={2}>
                    <RadioGroup
                      row
                      sx={{ justifyContent: "center" }}
                      onChange={(event, value) => {
                        const upgradeRequired = JSON.parse(JSON.stringify(diagnosis?.upgradeRequired))
                        upgradeRequired[idx]!.grade = value

                        if (value == "필요") upgradeRequired[idx]!.required = true
                        else {
                          upgradeRequired[idx]!.required = false
                          upgradeRequired[idx]!.fee = 0
                        }
                        setDiagnosis({ ...diagnosis, upgradeRequired: upgradeRequired })
                      }}
                    >
                      <FormControlLabel
                        value="필요"
                        label="필요"
                        checked={diagnosis?.upgradeRequired?.[idx]?.required}
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value="불필요"
                        label="불필요"
                        checked={!diagnosis?.upgradeRequired?.[idx]?.required}
                        control={<Radio />}
                      />
                    </RadioGroup>
                  </td>
                  <td>
                    <TextField
                      variant="standard"
                      type={"number"}
                      defaultValue={0}
                      value={diagnosis?.upgradeRequired?.[idx]?.fee}
                      onChange={(event) => {
                        const upgradeRequired = JSON.parse(JSON.stringify(diagnosis?.upgradeRequired))
                        upgradeRequired[idx]!.fee = event.target.value
                        setDiagnosis({ ...diagnosis, upgradeRequired: upgradeRequired })
                      }}
                      onWheel={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        return
                      }}
                      onKeyDown={(event) => {
                        if (/\d/.test(event.key)) return
                        if ("Backspace" === event.key || "Delete" === event.key) return
                        event.preventDefault()
                      }}
                    />
                  </td>
                  <td colSpan={2}>
                    <TextField
                      variant="standard"
                      fullWidth={true}
                      type={"text"}
                      multiline={true}
                      value={diagnosis?.upgradeRequired?.[idx]!.note}
                      onChange={(event) => {
                        const upgradeRequired = JSON.parse(JSON.stringify(diagnosis?.upgradeRequired))
                        upgradeRequired[idx]!.note = event.target.value
                        setDiagnosis({ ...diagnosis, upgradeRequired: upgradeRequired })
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <h2>종합상태</h2>
          <table className={"basic"}>
            <tbody>
              <tr>
                <td className={"header"}>종합상태</td>
                <td className={"header"}>판매 불가 사유</td>
              </tr>
              <tr>
                <td>
                  <RadioGroup
                    row
                    sx={{ justifyContent: "center" }}
                    onChange={(event, value) => {
                      const changedGradeCode = value as DiagnosisGradeCode
                      const hasNoPaneltyStatus =
                        changedGradeCode === DiagnosisGradeCode.양호 || changedGradeCode === DiagnosisGradeCode.보통
                      setDiagnosis({
                        ...diagnosis,
                        grade: changedGradeCode,
                        penaltyReasonType: hasNoPaneltyStatus ? undefined : diagnosis.penaltyReasonType,
                      })
                    }}
                  >
                    <FormControlLabel
                      value="양호"
                      label="양호"
                      checked={diagnosis?.grade == DiagnosisGradeCode.양호}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="보통"
                      label="보통"
                      checked={diagnosis?.grade == DiagnosisGradeCode.보통}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="업그레이드 필요"
                      label="업그레이드 필요"
                      checked={diagnosis?.grade == DiagnosisGradeCode.업그레이드_필요}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="판매 불가"
                      label="판매 불가"
                      checked={diagnosis?.grade == DiagnosisGradeCode.판매_불가}
                      control={<Radio />}
                    />
                  </RadioGroup>
                </td>
                <td>
                  <TextField
                    variant="standard"
                    fullWidth={true}
                    type={"text"}
                    multiline={true}
                    value={diagnosis?.failedReason}
                    onChange={(event) => {
                      setDiagnosis({ ...diagnosis, failedReason: event.target.value })
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <h2>판매 불가 페널티</h2>
          <table className={"basic"}>
            <tbody>
              <tr>
                <td className={"header"}>페널티 발생</td>
                <td className={"header"}>페널티 없음</td>
              </tr>
              <tr>
                <td>
                  <RadioGroup
                    row
                    sx={{ justifyContent: "center" }}
                    onChange={(event, value) => {
                      // @ts-ignore
                      setDiagnosis({ ...diagnosis, penaltyReasonType: event.target.value || undefined })
                    }}
                  >
                    <FormControlLabel
                      value="가품"
                      label="가품"
                      checked={diagnosis?.penaltyReasonType == PenaltyReasonTypeCode.가품}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="취급불가 커스텀"
                      label="취급불가 커스텀"
                      checked={diagnosis?.penaltyReasonType == PenaltyReasonTypeCode.취급불가_커스텀}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="상태 불일치"
                      label="상태 불일치"
                      checked={diagnosis?.penaltyReasonType == PenaltyReasonTypeCode.상태_불일치}
                      control={<Radio />}
                    />
                  </RadioGroup>
                </td>
                <td>
                  <RadioGroup
                    row
                    sx={{ justifyContent: "center" }}
                    onChange={(event, value) => {
                      // @ts-ignore
                      setDiagnosis({ ...diagnosis, penaltyReasonType: event.target.value || null })
                    }}
                  >
                    <FormControlLabel
                      value="위탁 전환"
                      label="위탁 전환"
                      checked={diagnosis?.penaltyReasonType == PenaltyReasonTypeCode.위탁_전환}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={undefined}
                      label="해당사항 없음"
                      checked={!diagnosis?.penaltyReasonType}
                      control={<Radio />}
                    />
                  </RadioGroup>
                </td>
              </tr>
            </tbody>
          </table>
          <h2>진단결과</h2>
          <table className={"basic"}>
            <tbody>
              <tr>
                <td>
                  <RadioGroup
                    row
                    sx={{ justifyContent: "center" }}
                    onChange={(event, value) => {
                      // @ts-ignore
                      setDiagnosis({ ...diagnosis, result: event.target.value || null })
                    }}
                  >
                    <FormControlLabel
                      value={DiagnosisResultCode.합격}
                      label={DiagnosisResultCode.합격}
                      checked={diagnosis?.result == DiagnosisResultCode.합격}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={DiagnosisResultCode.불합격}
                      label={DiagnosisResultCode.불합격}
                      checked={diagnosis?.result == DiagnosisResultCode.불합격}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={DiagnosisResultCode.보류}
                      label={DiagnosisResultCode.보류}
                      checked={diagnosis?.result == DiagnosisResultCode.보류}
                      control={<Radio />}
                    />
                  </RadioGroup>
                </td>
              </tr>
            </tbody>
          </table>

          <h2>진단 상세 사진 첨부</h2>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>첨부 이미지 (최대 30장)</AccordionSummary>
            <AccordionDetails>
              <AttachmentImageList
                init={diagnosis?.images}
                defaultImageLabels={defaultImageLabels}
                save={(images: AttachmentType[]) => {
                  setDiagnosis({ ...diagnosis, images: images })
                }}
                max={30}
                disabled={saving || diagnosis?.status === "진단서 확정"}
              />
            </AccordionDetails>
          </Accordion>

          {(diagnosis?.status === "진단서 확정" || diagnosis?.status === "진단서 작성 완료") && (
            <>
              <h2>상품 가격 정보</h2>
              <h3>진단 전 고객에게 안내된 예상 최적판매가</h3>
              <table className={"basic"}>
                <tbody>
                  <tr>
                    <td className={"header"} colSpan={1}>
                      현재 시장 가격범위 (감가 미적용)
                    </td>
                    <td className={"ta-center"} colSpan={1}>{`${toRange(
                      estimatedPrice?.marketPrice?.min,
                      estimatedPrice?.marketPrice?.max,
                      4,
                      true
                    )} 만원`}</td>
                    <td className={"header"} colSpan={1}>
                      최근 30일간 시세 볼륨
                    </td>
                    <td className={"ta-center"} colSpan={1}>
                      {estimatedPrice.marketVolume}
                    </td>
                  </tr>
                  <tr>
                    <td className={"header"} colSpan={1}>
                      판매신청 당시 예상 가격범위 ({toDateStr(selectedSellOrder?.acceptedAt)})
                    </td>
                    <td className={"ta-center"} colSpan={1}>{`${toRange(
                      selectedSellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtOrder?.min,
                      selectedSellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtOrder?.max,
                      4
                    )} 만원`}</td>
                    <td className={"header"} colSpan={1}>
                      페널티 기준가
                    </td>
                    <td className={"ta-center"} colSpan={1}>{`${numberWithCommas(
                      truncate(product?.initialPrice, 4)
                    )} 만원`}</td>
                  </tr>
                  <tr>
                    <td className={"header"} colSpan={1}>
                      진단확정 당시 예상 가격범위 ({toDateStr(selectedSellOrder?.completeDiagnosisAt)})
                    </td>
                    <td className={"ta-center"} colSpan={1}>{`${toRange(
                      selectedSellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtDiagnosis?.min,
                      selectedSellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtDiagnosis?.max,
                      4,
                      true
                    )} 만원`}</td>
                    <td className={"header"}>페널티 금액</td>
                    <td>{numberWithCommas(penalty) || "-"} 원</td>
                  </tr>
                  <tr>
                    <td className={"header"} colSpan={1}>
                      현재 예상 가격범위
                    </td>
                    <td className={"ta-center"} colSpan={1}>{`${toRange(
                      estimatedPrice?.correctedEstimatedPrice?.min,
                      estimatedPrice?.correctedEstimatedPrice?.max,
                      4
                    )} 만원`}</td>
                    <td className={"header"} colSpan={1}>
                      고객 판매 희망가
                    </td>
                    <td className={"ta-center"} colSpan={1}>{`${numberWithCommas(
                      truncate(diagnosis?.userDesiredPrice, 4)
                    )} 만원`}</td>
                  </tr>
                  <tr>
                    <td colSpan={2} className={"no-border"} />
                    <td className={"header"} colSpan={1}>
                      네고 금액
                    </td>
                    <td className={"ta-center"} colSpan={1}>
                      {isEditingNegotiationPrice ? (
                        <NegotiationPriceEditor
                          negotiationPrice={product?.negotiatedAmount || 0}
                          onSavePrice={saveNegotiationPrice}
                          onCancleEdit={() => setIsEditingNegotiationPrice(false)}
                        />
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            padding: "0 6px",
                            display: "inline-flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {numberWithCommas((product?.negotiatedAmount || 0) * -1)}원
                          </div>
                          <Button
                            size={"small"}
                            variant={"outlined"}
                            color="error"
                            onClick={() => setIsEditingNegotiationPrice(true)}
                            disabled={isNotNil(product?.negotiatedAmount)}
                          >
                            수정
                          </Button>
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <h3>바이버 제안가격</h3>
              <table className={"basic"}>
                <tbody>
                  <tr>
                    <td className={"header"}>최적 판매가</td>
                    <td>
                      {diagnosis?.result == DiagnosisResultCode.불합격
                        ? "-"
                        : !!diagnosis?.viverPrices?.suggestedPrice
                          ? numberWithCommas(diagnosis?.viverPrices?.suggestedPrice)
                          : "-"}{" "}
                      원
                    </td>
                    <td className={"header"}>
                      최종 최적 판매가
                      <br />
                      (천원 단위 절삭)
                    </td>
                    <td>
                      {diagnosis?.result == DiagnosisResultCode.불합격
                        ? "-"
                        : numberWithCommas(floor(diagnosis?.viverPrices?.suggestedPrice, 4)) || "-"}{" "}
                      원
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>진단 총평</h2>
              <TextareaAutosize
                minRows={5}
                style={{ width: "100%" }}
                value={diagnosis?.generalReview}
                onChange={(event) => {
                  // @ts-ignore
                  setDiagnosis({ ...diagnosis, generalReview: event.target.value })
                }}
              />
              <Button
                size={"medium"}
                disabled={diagnosis?.status === "진단서 확정"}
                variant={"outlined"}
                onClick={() => generateGeneralReview(diagnosis)}
              >
                자동 작성
              </Button>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ textAlign: "right", margin: "10px 0" }}>
          <Button
            size={"medium"}
            disabled={saving}
            variant={"contained"}
            onClick={() => {
              return update(diagnosis)
            }}
          >
            진단서 변경내역 저장
          </Button>
          &nbsp;
          <Button
            size={"medium"}
            disabled={saving || diagnosis?.status === "진단서 작성 완료" || diagnosis?.status === "진단서 확정"}
            variant={"contained"}
            color={"success"}
            onClick={() => setComplete()}
          >
            진단서 작성완료
          </Button>
          &nbsp;
          <Button
            size={"medium"}
            disabled={saving || diagnosis?.status !== "진단서 작성 완료"}
            variant={"contained"}
            color={"error"}
            onClick={() => setConfirm()}
          >
            진단서 확정
          </Button>
          &nbsp;
          <AuthenticationIssuanceButton
            disabled={
              saving ||
              diagnosis?.status !== "진단서 확정" ||
              selectedSellOrder?.sellOrderStatus !== SellOrderStatusCode.판매_완료
            }
            productId={product?.id}
          />
          &nbsp;
          {!saving &&
            diagnosis?.status == "진단서 확정" &&
            selectedSellOrder?.sellOrderStatus === SellOrderStatusCode.판매_완료 && (
              <Button
                style={{ fontSize: "16px", fontWeight: 700, textDecoration: "underline" }}
                variant={"text"}
                size={"small"}
                onClick={() =>
                  window.open(`${window.location.origin}/diagnoses/issue-history?diagnosisId=${diagnosis.id}`, "_blank")
                }
              >
                <span>진단서 발급 이력 바로가기</span>
              </Button>
            )}
          &nbsp;
        </div>
        <Button size={"medium"} variant={"outlined"} color={"error"} onClick={() => handleClose()}>
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditBeforeDiagnosisDiagnosisPopup
