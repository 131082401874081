import React, { useEffect, useState } from "react"
import {SellOrderType} from "types/SellOrderType";
import ProductQR from "components/ProductQR";
import {toDateTimeStr} from "utils/datetimeUtil";
import {ProductType} from "types/ProductType";
import {getSellOrder} from "../../../apis/sellOrderAPI";
import {getProduct} from "../../../apis/productAPI";

type QRTableToPrintProps = {
    productId?: number,
}

const QRTableToPrint: React.FC<QRTableToPrintProps> = ({productId}) => {
    const [product, setProduct] = useState<ProductType>()
    const [sellOrder, setSellOrder] = useState<SellOrderType>()

    useEffect(()=> {
        if (productId) {
            productId && getProduct(productId).then((res) => {
                setProduct(res)
                res?.sellOrder && getSellOrder(res.sellOrder.id!).then(setSellOrder)
            })
        }
    }, [productId])

    return (
            <table className={"basic label"} style={{fontSize: '14px'}}>
                <tbody>
                    <tr>
                        <td className={"header"} colSpan={6}>{`#${product?.id} _ ${product?.saleType} _ ECU 부착용`}</td>
                        <td colSpan={2} rowSpan={4} align={'center'}>
                            <ProductQR size={80} id={product?.id}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={"header"}>상품명</td>
                        <td style={{ textAlign: "left", paddingLeft: "10px" }} colSpan={5}>{product?.title}<br />{product?.titleSecondary} <b>({product?.exposedProductInfo?.productCondition})</b></td>
                    </tr>
                    <tr>
                        <td className={"header"}>상품번호</td>
                        <td colSpan={2}>{product?.id}</td>
                        <td className={"header"} >판매주문ID</td>
                        <td colSpan={2}>{sellOrder?.id}</td>
                    </tr>
                    <tr>
                        <td className={"header"} >입고확인</td>
                        <td colSpan={2}>
                            {toDateTimeStr(sellOrder?.waitingDiagnosisAt)}
                        </td>
                        <td className={"header"} >판매자</td>
                        <td colSpan={2}>{sellOrder?.seller?.name} {sellOrder?.seller?.phone}</td>
                    </tr>
                    <tr></tr>
                    <tr>
                        <td className={"header"} colSpan={6}>{`#${product?.id} _ ${product?.saleType} _ 폴리백 부착용`}</td>
                        <td colSpan={2} rowSpan={4} align={'center'}>
                            <ProductQR size={80} id={product?.id}/>
                        </td>
                    </tr>
                    <tr>
                        <td className={"header"}>상품명</td>
                        <td style={{ textAlign: "left", paddingLeft: "10px" }} colSpan={5}>{product?.title}<br />{product?.titleSecondary} <b>({product?.exposedProductInfo?.productCondition})</b></td>
                    </tr>
                    <tr>
                        <td className={"header"}>상품번호</td>
                        <td colSpan={2}>{product?.id}</td>
                        <td className={"header"}>판매주문ID</td>
                        <td colSpan={2}>{sellOrder?.id}</td>
                    </tr>
                    <tr>
                        <td className={"header"} >입고확인</td>
                        <td colSpan={2}>
                            {toDateTimeStr(sellOrder?.waitingDiagnosisAt)}
                        </td>
                        <td className={"header"} >판매자</td>
                        <td colSpan={2}>{sellOrder?.seller?.name} {sellOrder?.seller?.phone}</td>
                    </tr>
                    <tr>
                        <td className={"header"} rowSpan={4}>부속품</td>
                        <td className={"ta-left"} colSpan={2}><span style={{ margin: "0px 5px"}}>☐</span>외부박스</td>
                        <td className={"ta-left"} colSpan={2}><span style={{ margin: "0px 5px"}}>☐</span>내부박스</td>
                        <td className={"ta-left"} colSpan={3}><span style={{ margin: "0px 5px"}}>☐</span>COSC인증책자</td>
                    </tr>
                    <tr>
                        <td className={"ta-left"} colSpan={2}><span style={{ margin: "0px 5px"}}>☐</span>메달</td>
                        <td className={"ta-left"} colSpan={2}><span style={{ margin: "0px 5px"}}>☐</span>보증서케이스</td>
                        <td className={"ta-left"} colSpan={3}><span style={{ margin: "0px 5px"}}>☐</span>사용설명서 (<span style={{ margin: "0px 5px"}}>국</span>/<span style={{ margin: "0px 5px"}}>외</span>)</td>
                    </tr>
                    <tr>
                        <td className={"ta-left"} colSpan={2}><span style={{ margin: "0px 5px"}}>☐</span>쇼핑백</td>
                        <td className={"ta-left"} colSpan={2}><span style={{ margin: "0px 5px"}}>☐</span>영수증</td>
                        <td className={"ta-left"} colSpan={3}><span style={{ margin: "0px 5px"}}>☐</span>여분링크</td>
                    </tr>
                    <tr>
                        <td className={"ta-left"} colSpan={7} style={{ paddingLeft: "5px" }}>기타</td>
                    </tr>
                </tbody>
            </table>
    )
}

export default QRTableToPrint
