import styled from "@emotion/styled"
import React, { useCallback, useState } from "react"
import { useMount } from "react-use"
import { PaginationParamsType } from "types/PageType"
import { toast } from "react-toastify"
import ProductRequestTable from "./ProductRequestTable"
import { ProductRequestType } from "../../types/ProductRequestType"
import { getProductRequestList } from "../../apis/productRequestAPI"
import { BrandType } from "../../types/BrandType"
import { getBrands } from "../../apis/brandAPI"
import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField } from "@mui/material"
import { DesktopDatePicker } from "@mui/lab"
import { DateTime } from "luxon"
import ViewProductRequestPopup from "./ViewProductRequestPopup"

export interface ProductRequestState {
  userId?: number
  brandSid: string
  searchFilter: string
  searchWord: string
  productUsedCondition: string
  startDate?: DateTime
  endDate?: DateTime
}

const initSearchParams: ProductRequestState = {
  userId: undefined,
  brandSid: "ALL",
  searchFilter: "modelName",
  searchWord: "",
  productUsedCondition: "ALL",
  startDate: undefined,
  endDate: undefined,
}

const ProductRequests = () => {
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: 25,
  })

  const [searchParams, setSearchParams] = useState<any>(initSearchParams)
  const [brandList, setBrandList] = useState<BrandType[]>([])
  const [productRequestList, setProductRequestList] = useState<ProductRequestType[]>([])
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined)

  const onChangeUserId = useCallback((userId: any) => {
    setSearchParams((prev: any) => ({ ...prev, userId: userId }))
  }, [])

  const onChangeBrandSid = useCallback((brandSid: any) => {
    setSearchParams((prev: any) => ({ ...prev, brandSid: brandSid }))
  }, [])

  const onChangeSearchFilter = useCallback((searchFilter: any) => {
    setSearchParams((prev: any) => ({ ...prev, searchFilter: searchFilter }))
  }, [])

  const onChangeSearchWord = useCallback((searchWord: any) => {
    setSearchParams((prev: any) => ({ ...prev, searchWord: searchWord }))
  }, [])

  const onChangeProductUsedCondition = useCallback((productUsedCondition: any) => {
    setSearchParams((prev: any) => ({ ...prev, productUsedCondition: productUsedCondition }))
  }, [])

  const onChangeStartDate = useCallback((value: any) => {
    setSearchParams((prev: any) => ({ ...prev, startDate: value }))
  }, [])

  const onChangeEndDate = useCallback((value: any) => {
    setSearchParams((prev: any) => ({ ...prev, endDate: value }))
  }, [])

  const parseSearchParams = useCallback((searchParams?: any) => {
    if (!searchParams) {
      return {}
    }

    return {
      "userId": searchParams.userId,
      "brandSid": searchParams.brandSid === "ALL" ? null : searchParams.brandSid,
      [searchParams.searchFilter]: searchParams.searchWord,
      "productUsedCondition": searchParams.productUsedCondition === "ALL" ? null : searchParams.productUsedCondition,
      "startDate": searchParams.startDate?.toISODate(),
      "endDate": searchParams.endDate?.toISODate(),
    }
  }, [])

  function fetchList({ pageNumber, pageSize, searchParams }: { pageNumber?: number; pageSize?: number; searchParams?: any; }) {
    getProductRequestList({
      size: pageSize ?? paginationParams.size,
      page: pageNumber,
      ...parseSearchParams(searchParams),
    })
      .then(({ content, totalElements = 0, totalPages = 0, first, size = pageSize ?? 25, number = 0 }) => {
        setProductRequestList(content.map((listItem) => ({ ...listItem, isChecked: false })))
        setPaginationParams((prev) => ({
          ...prev,
          number,
          totalElements,
          totalPages,
          first,
          size,
        }))
        setSearchParams((prev: any) => ({ ...prev, selectedIds: [] }))
      })
      .catch(() => {
        toast.error("트레이드업 신청 내역을 불러오는데 실패했습니다.")
      })
  }

  const onPageChange = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber }))
      fetchList({
        pageNumber: pageNumber,
        searchParams: searchParams,
      })
    },
    [paginationParams],
  )

  const onChangePageSize = useCallback((pageSize: number) => {
      setPaginationParams((prev) => ({ ...prev, size: pageSize }))
      fetchList({
        pageNumber: 0,
        pageSize: pageSize,
        searchParams: searchParams,
      })
    },
    [paginationParams],
  )

  const openProductRequestImagePopup = useCallback((id: string) => {
      setSelectedId(id)
      setOpenPopup(true)
    },
    [openPopup],
  )

  const onSearch = useCallback(() => {
    fetchList({
      pageNumber: 0,
      searchParams: searchParams,
    })
    setPaginationParams((prev) => ({ ...prev, number: 0 }))
  }, [fetchList])

  useMount(() => {
    fetchList({})
    getBrands().then(brandList => setBrandList(brandList))
  })

  return (
    <PageBodyWrapper>
      <ViewProductRequestPopup
        open={openPopup}
        productRequestId={selectedId!!}
        handleClose={() => {
          setSelectedId(undefined)
          setOpenPopup(false)
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Grid item xs={12}>
              <Stack spacing={1} direction={"column"}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack direction={"row"}>
                    <TextField
                      sx={{ width: "100px" }}
                      placeholder={"회원ID"}
                      size={"small"}
                      value={searchParams.userId || ""}
                      onChange={(e) => onChangeUserId(e.target.value)}
                    />
                    <FormControl sx={{ width: "160px" }} size={"small"}>
                      <InputLabel id="search_key">브랜드</InputLabel>
                      <Select
                        defaultValue={"ALL"}
                        required
                        value={searchParams.brandSid}
                        onChange={(e) => onChangeBrandSid(e.target.value)}
                      >
                        <MenuItem value={"ALL"}>전체</MenuItem>
                        {brandList.map((brand) => (<MenuItem value={brand.sid}>{brand.name}</MenuItem>))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ width: "160px" }} size={"small"}>
                      <InputLabel id="search_key">검색항목</InputLabel>
                      <Select
                        defaultValue={"modelName"}
                        required
                        value={searchParams.searchFilter}
                        onChange={(e) => onChangeSearchFilter(e.target.value)}
                      >
                        <MenuItem value={"modelName"}>모델명</MenuItem>
                        <MenuItem value={"fullRefNo"}>Full Ref.No</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      sx={{ width: "130px" }}
                      size={"small"}
                      value={searchParams.searchWord || ""}
                      onChange={(e) => onChangeSearchWord(e.target.value)}
                    />
                    <FormControl sx={{ width: "160px" }} size={"small"}>
                      <InputLabel id="search_key">제품상태</InputLabel>
                      <Select
                        defaultValue={"ALL"}
                        required
                        value={searchParams.productCondition}
                        onChange={(e) => onChangeProductUsedCondition(e.target.value)}
                      >
                        <MenuItem value={"ALL"}>전체</MenuItem>
                        <MenuItem value={"미착용"}>미착용</MenuItem>
                        <MenuItem value={"중고"}>중고</MenuItem>
                      </Select>
                    </FormControl>
                    <DesktopDatePicker
                      label="신청일 시작"
                      inputFormat="yyyy-MM-dd"
                      value={searchParams.startDate ?? null}
                      renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                      onChange={(params) => onChangeStartDate(params)}
                    />
                    <DesktopDatePicker
                      label="신청일 종료"
                      inputFormat="yyyy-MM-dd"
                      value={searchParams.endDate ?? null}
                      renderInput={(params) => <TextField {...params} sx={{ width: "160px" }} size={"small"} />}
                      onChange={(params) => onChangeEndDate(params)}
                    />
                  </Stack>
                  <Button variant={"outlined"} size={"small"} style={{ margin: "0 2px" }} onClick={onSearch}>검색</Button>
                </Stack>
              </Stack>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ProductRequestTable
        rows={productRequestList ?? []}
        onPageChange={onPageChange}
        onPageSizeChange={onChangePageSize}
        openProductRequestImagePopup={openProductRequestImagePopup}
        page={paginationParams.number}
        size={paginationParams.size}
        totalCount={paginationParams.totalElements ?? 0}
      />
    </PageBodyWrapper>
  )
}

const PageBodyWrapper = styled.div`
    width: 100%;
    background: #fff;
    padding: 24px 16px;
    border-radius: 4px;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
`

const SearchBarWrapper = styled.div`
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 28px;
`
export default ProductRequests