import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material"
import { DateTime } from "luxon"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { defaultImageLabels, ProductStatusCode, SaleTypeCode, UsedConditionLevelCode } from "types/ProductType"
import { SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import "App.css"
import { toDateStr } from "utils/datetimeUtil"
import {
  Accessories,
  AccessoriesConditionCode,
  AttachmentType,
  DiagnosisGradeCode,
  OfficialGuaranteeCode,
  OriginalBoxConditionCode,
  ProductConditionCode,
} from "types/DiagnosisType"
import { numberWithCommas, toRange, truncateWithCommas } from "utils/NumberUtils"
import ProductPriceHistoryPopup from "./ProductPriceHistoryPopup"
import { ReSellGuaranteedOption } from "./ReSellGuaranteedOption"
import { useEditPreProductData } from "./useEditPreProductData"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import AttachmentImageList from "pages/Diagnoses/AttachmentImageList"
import { isEmpty, isNaN } from "lodash"
import QuickMemoButton from "../../components/QuickMemo/QuickMemoButton"
import { UserMemoTargetTypeCode } from "../../types/UserType"
import { SerialNumberFinder } from "components/SerialNumber/SerialNumberFinder"
import { getSellOrder } from "../../apis/sellOrderAPI"
import { cancelOnSitePayment, completeOnSitePayment, getProduct, updateProduct } from "../../apis/productAPI"
import { toast } from "react-toastify"
import { getDiagnosisFee } from "../../apis/diagnosisAPI"
import SellOrderInformation from "./EditPopup/SellOrderInformation"
import ProductInformation from "./EditPopup/ProductInformation"
import { isNotNil } from "utils/validationUtils"
import { BoxHorizontal } from "@viver-inc/viver-ui"
import { getCardCompanies } from "apis/cardCompanyAPI"
import { getMethodTypes } from "../../apis/paymentAPI"
import { PaymentMethodTypeCode, PaymentMethodTypeGroupCode } from "../../types/PaymentType"
import { getBuyingFees } from "../../apis/feePolicyAPI"

type EditPreProductPopupProps = {
  open: boolean
  selectedProductId?: number
  selectedSellOrderId?: string
  handleClose: Function
}

const EditPreProductPopup: React.FC<EditPreProductPopupProps> = ({ open, selectedProductId, handleClose }) => {
  const {
    brand,
    product,
    setProduct,
    toggleReSellGuaranteeOption,
    changeProductGuaranteeState,
    finishProductSetUp,
    changeProductPrice,
    updateOnDisplay,
    generateGeneralReview,
    saving,
    getCurrentResellPrice,
    estimatedPrice,
    penalty,
    editing,
    setEditing,
    update,
    productPrice,
    setProductPrice,
    formattedDate,
    setFormattedDate,
  } = useEditPreProductData({
    productId: selectedProductId,
    handleClose,
  })

  const [sellOrder, setSellOrder] = useState<SellOrderType | undefined>()
  const [priceHistoryPopup, setPriceHistoryPopup] = useState(false)

  const [diagnosisType, setDiagnosisType] = useState<"일반 감정진단" | "정밀 감정진단">("일반 감정진단")
  const [diagnosisFee, setDiagnosisFee] = useState<{ basic: number; advanced: number }>({ basic: 0, advanced: 0 })

  const [buyingFeePolicyList, setBuyingFeePolicyList] = useState<
    { label: string; amount: number; feePolicyType: string }[]
  >([])
  const [buyingFee, setBuyingFee] = useState<{ feePolicyType: string; amount: number }>()

  const [onSiteCardCodeList, setOnSiteCardCodeList] = useState<{ value: string; label: string }[]>([])
  const [onSitePaymentCardCompany, setOnSitePaymentCardCompany] = useState<{ value: string; label: string } | null>(
    null
  )
  const [onSitePaymentMode, setOnSitePaymentMode] = useState<"단일결제" | "분할결제">("단일결제")
  const [onSitePaymentMethodTypeGroup, setOnSitePaymentMethodTypeGroup] = useState<
    PaymentMethodTypeGroupCode | undefined
  >(PaymentMethodTypeGroupCode.CARD)
  const [onSitePaymentInputMethod, setOnSitePaymentInputMethod] = useState<PaymentMethodTypeCode | null>(
    PaymentMethodTypeCode.신용카드
  )
  const [onSitePaymentMethodTypeList, setOnSitePaymentMethodTypeList] = useState<PaymentMethodTypeCode[]>([
    PaymentMethodTypeCode.신용카드,
  ])
  const [onSiteIsCashReceipt, setOnSiteIsCashReceipt] = useState<boolean>(false)
  const [onSiteIsDiagnosisFee, setOnSiteIsDiagnosisFee] = useState<boolean>(true)
  const [onSiteDiagnosisFeePayment, setOnSiteDiagnosisFeePayment] = useState<boolean>(false)

  const [taxFreeRefund, setTaxFreeRefund] = useState<boolean>(false)

  const [onSitePaymentInputAmount, setOnSitePaymentInputAmount] = useState("")

  const [onSitePayments, setOnSitePayments] = useState<
    {
      paymentMethodTypeGroup: PaymentMethodTypeGroupCode
      paymentMethodType: PaymentMethodTypeCode
      paymentAmount: number
      cardCompany?: { value: string; label: string }
      cashReceipt: boolean
      diagnosisFeePayment: boolean
    }[]
  >([])

  const [productEmbeddedVideoUrl, setProductEmbeddedVideoUrl] = useState("")

  const onSiteSellPaymentAmountTotal = useMemo(() => {
    if (onSitePaymentMode === "분할결제") {
      return onSitePayments.reduce((totalPaymentAmount, { paymentAmount }) => totalPaymentAmount + paymentAmount, 0)
    }

    return parseInt(onSitePaymentInputAmount) || 0
  }, [onSitePaymentInputAmount, onSitePaymentMode, onSitePayments])

  const onSiteSellFinalPrice = useMemo(() => {
    return (
      (product.price ?? 0) +
      (!onSiteIsDiagnosisFee ? 0 : diagnosisType === "정밀 감정진단" ? diagnosisFee.advanced : diagnosisFee.basic) +
      (buyingFee?.amount ?? 0)
    )
  }, [diagnosisFee.advanced, diagnosisFee.basic, diagnosisType, product, onSiteIsDiagnosisFee, buyingFee])

  const handlePaymentMethodTypeGroupChange = async (params: any) => {
    setOnSitePaymentMethodTypeGroup(params)
    getMethodTypes(params).then((methodTypes) => {
      setOnSitePaymentMethodTypeList(methodTypes?.methodTypes)
      if (params === PaymentMethodTypeGroupCode.CARD) {
        setOnSitePaymentInputMethod(methodTypes?.methodTypes[0])
        setOnSiteIsCashReceipt(false)
      } else if (params === PaymentMethodTypeGroupCode.EASY_PAY) {
        setOnSitePaymentInputMethod(null)
        setOnSiteIsCashReceipt(false)
      } else if (params === PaymentMethodTypeGroupCode.CASH) {
        setOnSitePaymentInputMethod(methodTypes?.methodTypes[0])
        setOnSiteIsCashReceipt(true)
      } else if (params === PaymentMethodTypeGroupCode.NOT_VIVER) {
        setOnSitePaymentInputMethod(null)
        setOnSiteIsCashReceipt(false)
        setOnSiteIsDiagnosisFee(true)
        setDiagnosisType("정밀 감정진단")
      }
    })
  }

  useEffect(() => {
    if (open) {
      selectedProductId &&
        getProduct(selectedProductId).then((res) => {
          setProduct(res)

          res?.embeddedVideoUrl && setProductEmbeddedVideoUrl(res.embeddedVideoUrl)

          res?.sellOrder &&
            getSellOrder(res.sellOrder.id!).then((res) => {
              setSellOrder(res)
            })

          getDiagnosisFee(selectedProductId, res.buyOrder?.buyer?.id ?? undefined).then((result) => {
            const getBasicDiagnosisFee = () => {
              const list = result.filter((item) => item.feePolicyType === "구매 진단 수수료")
              if (list.length === 0) return 0

              return list[0].amount
            }
            const getAdvancedDiagnosisFee = () => {
              const list = result.filter((item) => item.feePolicyType === "구매 정밀 진단 수수료")
              if (list.length === 0) return 0

              return list[0].amount
            }

            setDiagnosisFee({
              basic: getBasicDiagnosisFee(),
              advanced: getAdvancedDiagnosisFee(),
            })
          })

          getBuyingFees(selectedProductId, res.buyOrder?.buyer?.id ?? undefined).then((buyingFees) => {
            setBuyingFeePolicyList(
              buyingFees.map((buyingFee) => ({
                label: `${buyingFee.feePolicyType} ${buyingFee.calculationType === "정률" ? buyingFee.rate * 100 + "%" : ""}`,
                amount: buyingFee.amount,
                feePolicyType: buyingFee.feePolicyType,
              }))
            )
            setBuyingFee({
              amount: buyingFees[0].amount,
              feePolicyType: buyingFees[0].feePolicyType,
            })
          })
        })
      setTaxFreeRefund(false)
      setOnSiteIsDiagnosisFee(true)
      setOnSiteDiagnosisFeePayment(false)
      if (onSitePaymentMethodTypeGroup === "CARD") {
        getCardCompanies().then((cardCompanies) =>
          setOnSiteCardCodeList(
            cardCompanies
              .sort(({ displayName: a }, { displayName: b }) => {
                if (a < b) return -1
                if (a > b) return 1
                return 0
              })
              .map((cardCompany) => ({
                value: cardCompany.code,
                label: cardCompany.displayName,
              }))
          )
        )
      } else if (onSitePaymentMethodTypeGroup === "EASY_PAY" || onSitePaymentMethodTypeGroup === "NOT_VIVER") {
        getMethodTypes(onSitePaymentMethodTypeGroup).then((methodTypes) => {
          setOnSitePaymentMethodTypeList(methodTypes.methodTypes)
          setOnSitePaymentInputMethod(null)
        })
      }
    } else {
      setSellOrder(undefined)

      setOnSitePaymentMode("단일결제")
      setOnSitePaymentInputMethod(PaymentMethodTypeCode.신용카드)
      setOnSitePaymentInputAmount("")
      setOnSitePaymentCardCompany(null)
      setOnSitePayments([])

      setProductEmbeddedVideoUrl("")
    }
  }, [open, selectedProductId, setProduct])

  useEffect(() => {
    setTaxFreeRefund((prevState) => prevState)
  }, [taxFreeRefund])

  useEffect(() => {
    setOnSiteIsCashReceipt((prevState) => prevState)
  }, [onSiteIsCashReceipt])

  useEffect(() => {
    setOnSitePaymentMethodTypeGroup((prevState) => prevState)
  }, [onSitePaymentMethodTypeGroup])

  const isEditingDisabled = useMemo(() => {
    return (
      sellOrder?.sellOrderStatus !== SellOrderStatusCode.판매_대기 &&
      sellOrder?.sellOrderStatus !== SellOrderStatusCode.판매중
    )
  }, [sellOrder])

  const isProductInformationEditable = useMemo(() => {
    /* 판매중이 아닌 경우 상품 정보 수정 불가*/
    return product.status === ProductStatusCode.판매_대기 || product.status === ProductStatusCode.판매중
  }, [product.status])

  const isNotEditableUnUsedProduct = useMemo(() => {
    return (
      product.saleType !== SaleTypeCode.직접판매 &&
      product.exposedProductInfo?.productCondition === ProductConditionCode.미착용
    )
  }, [product.exposedProductInfo?.productCondition, product.saleType])

  const onPressSellOnShowRoom = useCallback(() => {
    if (!sellOrder || !sellOrder.product || !sellOrder.product.id) return
    if (sellOrder.product.status === ProductStatusCode.판매_완료) return

    const confirmed = window.confirm(
      `쇼룸에서 ${onSitePaymentMode}로 ${numberWithCommas(onSiteSellFinalPrice)}원에 판매 처리 합니다`
    )
    if (!confirmed) {
      return
    }

    if (onSitePaymentMode === "단일결제") {
      if (onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.CARD) {
        if (isEmpty(onSitePaymentCardCompany)) {
          window.alert("결제수단 상세를 선택해주세요.")
          return
        }
      } else if (onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.EASY_PAY) {
        if (isEmpty(onSitePaymentInputMethod)) {
          window.alert("결제수단 상세를 선택해주세요.")
          return
        }
      } else if (onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.NOT_VIVER) {
        if (isEmpty(onSitePaymentInputMethod)) {
          window.alert("결제처를 선택해주세요.")
          return
        }
      }
      completeOnSitePayment({
        productId: sellOrder.product.id,
        productPrice: product.price ?? 0,
        advancedDiagnosis: diagnosisType === "정밀 감정진단",
        diagnosisFee: diagnosisType === "정밀 감정진단" ? diagnosisFee.advanced : diagnosisFee.basic,
        payments: [
          {
            paymentMethodType: onSitePaymentInputMethod!!,
            paymentAmount: parseInt(onSitePaymentInputAmount),
            cardCode: onSitePaymentCardCompany?.value,
            cashReceipt: onSiteIsCashReceipt,
          },
        ],
        isDiagnosisFee: onSiteIsDiagnosisFee,
        buyingFeePolicyType: buyingFee?.feePolicyType ?? "구매 수수료",
        buyingFee: buyingFee?.amount ?? 0,
        taxFreeRefund: taxFreeRefund,
      })
        .then(() => {
          toast.success("쇼룸 판매 처리가 완료되었습니다.")
          getSellOrder(sellOrder?.id!).then((res) => setSellOrder(res))
        })
        .catch((error) => {
          toast.error(`쇼룸 판매 처리에 실패했습니다.\n${error.message || error}`)
        })
    }
    if (onSitePaymentMode === "분할결제") {
      completeOnSitePayment({
        productId: sellOrder.product.id,
        productPrice: product.price ?? 0,
        advancedDiagnosis: diagnosisType === "정밀 감정진단",
        diagnosisFee: diagnosisType === "정밀 감정진단" ? diagnosisFee.advanced : diagnosisFee.basic,
        payments: onSitePayments.map((payment) => ({
          ...payment,
          cardCode: payment.cardCompany ? payment.cardCompany.value : "",
        })),
        isDiagnosisFee: onSiteIsDiagnosisFee,
        buyingFeePolicyType: buyingFee?.feePolicyType ?? "구매 수수료",
        buyingFee: buyingFee?.amount ?? 0,
        taxFreeRefund: taxFreeRefund,
      })
        .then(() => {
          toast.success("쇼룸 판매 처리가 완료되었습니다.")
          getSellOrder(sellOrder?.id!).then((res) => setSellOrder(res))
        })
        .catch((error) => {
          toast.error(`쇼룸 판매 처리에 실패했습니다.\n${error.message || error}`)
        })
    }
  }, [
    sellOrder,
    onSitePaymentMode,
    onSiteSellFinalPrice,
    onSitePaymentInputAmount,
    diagnosisType,
    diagnosisFee.advanced,
    diagnosisFee.basic,
    onSitePaymentInputMethod,
    onSitePaymentCardCompany,
    onSitePayments,
    product?.price,
    onSiteIsCashReceipt,
    onSiteIsDiagnosisFee,
    buyingFee?.feePolicyType,
    buyingFee?.amount,
    taxFreeRefund,
    onSitePaymentMethodTypeGroup,
  ])

  const onPressRefundOnShowRoom = useCallback(() => {
    if (!sellOrder || !sellOrder.product || !sellOrder.product.id) return

    if (sellOrder.product.status === ProductStatusCode.판매중) return

    const confirmed = window.confirm(`쇼룸에서 ${numberWithCommas(onSiteSellFinalPrice)}원에 환불 처리 합니다`)

    if (!confirmed) {
      return
    }

    cancelOnSitePayment(sellOrder.product.id).then(() => {
      toast.success("쇼룸 환불 처리가 완료되었습니다.")
      getSellOrder(sellOrder?.id!).then((res) => setSellOrder(res))
    })
  }, [sellOrder, onSiteSellFinalPrice])

  const onPressSubmitProductEmbeddedVideoUrl = () => {
    if (!sellOrder || !sellOrder.product || !sellOrder.product.id) return

    updateProduct(sellOrder.product.id, {
      embeddedVideoUrl: productEmbeddedVideoUrl,
    })
      .then((product) => {
        setProduct(product)
        toast.success("YouTube 주소를 저장했습니다.")
      })
      .catch((error) => {
        toast.error(`YouTube 주소를 저장하는 중 오류가 발생했습니다.\n${error.message || error}`)
      })
  }

  return (
    <Dialog open={open} maxWidth={"xl"} fullWidth>
      <ProductPriceHistoryPopup
        open={priceHistoryPopup}
        productId={product?.id}
        handleClose={() => {
          setPriceHistoryPopup(false)
        }}
      />
      <DialogTitle>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td colSpan={3} className={"no-border ta-left"}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                  <Typography fontWeight={"bold"} fontSize={"30px"} alignItems={"center"}>
                    상품 정보
                  </Typography>
                  <QuickMemoButton target={UserMemoTargetTypeCode.PRODUCT} targetId={product?.id} small />
                </Stack>
              </td>
              <td className={"header"}>상품번호</td>
              <td className={"header"}>상품화</td>
              <td className={"header"}>상품 위치</td>
              <td className={"header"}>노출 상태</td>
              <td className={"header"}>판매 상태</td>
            </tr>
            <tr>
              <td colSpan={3} className={"no-border"}></td>
              <td>
                {product?.id}
                <QuickMemoButton target={UserMemoTargetTypeCode.PRODUCT} targetId={product?.id} small />
              </td>
              <td>{!!product?.completeSetupAt ? "완료" : "미완료"}</td>
              <td>{product.location || "-"}</td>
              <td>
                <Switch
                  checked={product?.onDisplay}
                  color={"success"}
                  onChange={(event, checked) => {
                    setProduct((prevProduct) => ({ ...prevProduct, onDisplay: checked }))
                    updateOnDisplay(checked)
                  }}
                />
              </td>
              <td className={"bold"}>{saving ? "저장중..." : sellOrder?.sellOrderStatus}</td>
            </tr>
          </tbody>
        </table>
      </DialogTitle>
      <DialogContent>
        {isNotNil(sellOrder) ? (
          <SellOrderInformation sellOrder={sellOrder} />
        ) : (
          <ProductInformation product={product} />
        )}
        {(sellOrder?.product?.saleType === "직접판매" || sellOrder?.product?.saleType === "위탁판매") && (
          <>
            <h2>쇼룸 판매</h2>
            <table className={"basic"} style={{ marginTop: 16 }}>
              <tbody>
                <tr>
                  <td className={"header"} width={128}>
                    상품구분
                  </td>
                  <td colSpan={onSitePaymentMode === "단일결제" ? 8 : 9}>
                    <BoxHorizontal justifyContent="space-between" paddingHorizontal={12}>
                      <BoxHorizontal>
                        <div>{product?.saleChannel == undefined ? "바이버" : product?.saleChannel}</div>
                      </BoxHorizontal>
                      <BoxHorizontal>
                        <div></div>
                      </BoxHorizontal>
                    </BoxHorizontal>
                  </td>
                </tr>
                <tr>
                  <td className={"header"} width={128}>
                    결제방법
                  </td>
                  <td colSpan={onSitePaymentMode === "단일결제" ? 8 : 9}>
                    <RadioGroup
                      row
                      value={onSitePaymentMode}
                      onChange={(event) => {
                        const nextPaymentMode = event.target.value as typeof onSitePaymentMode
                        setOnSitePaymentMode(nextPaymentMode)
                      }}
                      sx={{ marginLeft: "8px" }}
                    >
                      <FormControlLabel
                        value="단일결제"
                        label="단일결제"
                        checked={onSitePaymentMode === "단일결제"}
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value="분할결제"
                        label="분할결제"
                        checked={onSitePaymentMode === "분할결제"}
                        control={<Radio />}
                      />
                    </RadioGroup>
                  </td>
                </tr>

                <tr>
                  <td className={"header"} width={128} rowSpan={onSitePayments.length}>
                    결제수단
                  </td>

                  <td colSpan={2}>
                    <RadioGroup
                      row
                      value={onSitePaymentMethodTypeGroup}
                      onChange={(event) => {
                        const nextPaymentMethodTypeGroup = event.target.value as typeof onSitePaymentMethodTypeGroup
                        handlePaymentMethodTypeGroupChange(nextPaymentMethodTypeGroup)
                      }}
                      sx={{ marginLeft: "8px" }}
                    >
                      <FormControlLabel
                        value="CARD"
                        label="신용카드"
                        checked={onSitePaymentMethodTypeGroup === "CARD"}
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value="CASH"
                        label="현금"
                        checked={onSitePaymentMethodTypeGroup === "CASH"}
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value="EASY_PAY"
                        label="간편결제"
                        checked={onSitePaymentMethodTypeGroup === "EASY_PAY"}
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value="NOT_VIVER"
                        label="타처결제"
                        checked={onSitePaymentMethodTypeGroup === "NOT_VIVER"}
                        control={<Radio />}
                      />
                    </RadioGroup>
                  </td>

                  <td className={"header"} width={128}>
                    {onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.NOT_VIVER ? "결제처" : "결제수단 상세"}
                  </td>
                  <td>
                    {onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.CARD ? (
                      <Select
                        key={String(onSitePaymentCardCompany)}
                        required
                        value={onSitePaymentCardCompany?.value}
                        onChange={(event) => {
                          const cardCode = onSiteCardCodeList.find(({ value }) => value === event.target.value)
                          setOnSitePaymentCardCompany(cardCode ?? null)
                        }}
                        size="small"
                        fullWidth
                      >
                        {onSiteCardCodeList.map(({ value, label }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.EASY_PAY ||
                      onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.NOT_VIVER ||
                      onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.CASH ? (
                      <Select
                        key={String(onSitePaymentInputMethod)}
                        required
                        value={onSitePaymentInputMethod}
                        onChange={(event) => {
                          const paymentMethodTypeCode = event.target.value as PaymentMethodTypeCode
                          setOnSitePaymentInputMethod(paymentMethodTypeCode)
                          if (
                            paymentMethodTypeCode === PaymentMethodTypeCode.알리페이 ||
                            paymentMethodTypeCode === PaymentMethodTypeCode.제로페이
                          ) {
                            setOnSiteIsCashReceipt(true) //현금영수증 YES
                          }
                          if (paymentMethodTypeCode === PaymentMethodTypeCode.알리페이) {
                            setOnSiteIsDiagnosisFee(false) //진단비 무료
                            setDiagnosisType("일반 감정진단")
                            const feePolicyType = "외국인 구매 수수료"
                            const option = buyingFeePolicyList.find((o) => o.feePolicyType === feePolicyType)
                            setBuyingFee({
                              amount: option?.amount || (0 as number),
                              feePolicyType: option?.feePolicyType || ("직접 입력" as string),
                            })
                          }
                        }}
                        size="small"
                        fullWidth
                      >
                        {onSitePaymentMethodTypeList.map((paymentMethodType) => (
                          <MenuItem key={paymentMethodType} value={paymentMethodType}>
                            {paymentMethodType}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      "-"
                    )}
                  </td>

                  <td className={"header"} width={128} rowSpan={onSitePayments.length}>
                    현금영수증발행
                  </td>
                  <td>
                    {onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.EASY_PAY ? (
                      <Select
                        required
                        value={onSiteIsCashReceipt}
                        onChange={(event) => {
                          setOnSiteIsCashReceipt(event.target.value as boolean)
                        }}
                        size="small"
                        fullWidth
                        disabled={
                          (onSitePaymentInputMethod === "알리페이" || onSitePaymentInputMethod === "제로페이") &&
                          onSiteIsCashReceipt === true
                        }
                      >
                        <MenuItem value={true as any}>YES</MenuItem>
                        <MenuItem value={false as any}>NO</MenuItem>
                      </Select>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className={"header"} width={128}>
                    판매가
                  </td>
                  <td>
                    <TextField
                      fullWidth
                      placeholder={"0"}
                      size={"small"}
                      value={onSitePaymentInputAmount}
                      inputProps={{
                        style: {
                          textAlign: "right",
                        },
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">원</InputAdornment>,
                      }}
                      onChange={(event) => {
                        setOnSitePaymentInputAmount(event.target.value.replace(/[^0-9]/g, ""))
                      }}
                    />
                  </td>

                  {onSitePaymentMode === "분할결제" && (
                    <td width={128}>
                      {onSitePayments.some((item) => {
                        if (item.paymentMethodTypeGroup == PaymentMethodTypeGroupCode.NOT_VIVER) {
                          return true
                        }
                      }) ? (
                        <FormControlLabel
                          label={"진단비"}
                          control={
                            <Checkbox
                              checked={onSiteDiagnosisFeePayment}
                              onChange={(e) => setOnSiteDiagnosisFeePayment(e.target.checked)}
                            />
                          }
                        />
                      ) : (
                        ""
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (onSiteDiagnosisFeePayment) {
                            if (onSitePaymentMethodTypeGroup === "NOT_VIVER") {
                              window.alert(
                                "타처결제 건은 진단비로 추가할 수 없습니다. 선택하신 결제수단을 다시 한 번 확인해주세요."
                              )
                              return
                            }
                            if (!window.confirm("입력하신 금액을 진단비로 추가하시겠습니까?")) return
                          }

                          if (isEmpty(onSitePaymentInputAmount)) {
                            window.alert("분할결제 금액을 입력해주세요.")
                            return
                          }

                          const paymentAmount = parseInt(onSitePaymentInputAmount)
                          if (!Number.isFinite(paymentAmount)) {
                            window.alert("분할결제 금액을 바르게 입력해주세요.")
                            return
                          }

                          if (onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.CARD) {
                            if (isEmpty(onSitePaymentCardCompany)) {
                              window.alert("결제수단 상세를 선택해주세요.")
                              return
                            }
                            setOnSitePayments((payments) => [
                              ...payments,
                              {
                                paymentMethodTypeGroup: onSitePaymentMethodTypeGroup,
                                paymentMethodType: onSitePaymentInputMethod!!,
                                paymentAmount,
                                cardCompany: onSitePaymentCardCompany,
                                cashReceipt: onSiteIsCashReceipt,
                                diagnosisFeePayment: onSiteDiagnosisFeePayment,
                              },
                            ])
                          }
                          if (onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.CASH) {
                            setOnSitePayments((payments) => [
                              ...payments,
                              {
                                paymentMethodTypeGroup: onSitePaymentMethodTypeGroup,
                                paymentMethodType: onSitePaymentInputMethod!!,
                                paymentAmount,
                                cashReceipt: onSiteIsCashReceipt,
                                diagnosisFeePayment: onSiteDiagnosisFeePayment,
                              },
                            ])
                          }
                          if (onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.EASY_PAY) {
                            if (isEmpty(onSitePaymentInputMethod)) {
                              window.alert("결제수단 상세를 선택해주세요.")
                              return
                            }
                            setOnSitePayments((payments) => [
                              ...payments,
                              {
                                paymentMethodTypeGroup: onSitePaymentMethodTypeGroup,
                                paymentMethodType: onSitePaymentInputMethod!!,
                                paymentAmount,
                                cashReceipt: onSiteIsCashReceipt,
                                diagnosisFeePayment: onSiteDiagnosisFeePayment,
                              },
                            ])
                          }
                          if (onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.NOT_VIVER) {
                            if (isEmpty(onSitePaymentInputMethod)) {
                              window.alert("결제처를 선택해주세요.")
                              return
                            }
                            setOnSitePayments((payments) => [
                              ...payments,
                              {
                                paymentMethodTypeGroup: onSitePaymentMethodTypeGroup,
                                paymentMethodType: onSitePaymentInputMethod!!,
                                paymentAmount,
                                cashReceipt: onSiteIsCashReceipt,
                                diagnosisFeePayment: onSiteDiagnosisFeePayment,
                              },
                            ])
                          }
                          setOnSitePaymentMethodTypeGroup(PaymentMethodTypeGroupCode.CARD)
                          setOnSitePaymentMethodTypeList([PaymentMethodTypeCode.신용카드])
                          setOnSitePaymentInputMethod(PaymentMethodTypeCode.신용카드)
                          setOnSitePaymentInputAmount("")
                          setOnSitePaymentCardCompany(null)
                          setOnSiteIsCashReceipt(false)
                        }}
                      >
                        추가
                      </Button>
                    </td>
                  )}
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td className={"header"} width={128}>
                    진단비
                  </td>
                  <td>
                    <Select
                      required
                      value={onSiteIsDiagnosisFee}
                      onChange={(event) => {
                        setOnSiteIsDiagnosisFee(event.target.value as boolean)
                      }}
                      size="small"
                      fullWidth
                    >
                      <MenuItem value={true as any}>유료</MenuItem>
                      <MenuItem value={false as any}>무료</MenuItem>
                    </Select>
                  </td>
                  <td>
                    <RadioGroup
                      row
                      value={diagnosisType}
                      onChange={(event) => {
                        setDiagnosisType(event.target.value as "일반 감정진단" | "정밀 감정진단")
                      }}
                      sx={{ marginLeft: "8px" }}
                    >
                      <FormControlLabel
                        value="일반 감정진단"
                        label="일반 감정진단"
                        checked={diagnosisType === "일반 감정진단"}
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value="정밀 감정진단"
                        label="정밀 감정진단"
                        checked={diagnosisType === "정밀 감정진단"}
                        control={<Radio />}
                      />
                    </RadioGroup>
                  </td>

                  <td className={"header"} width={128}>
                    거래수수료
                  </td>
                  <td>
                    <Select
                      required
                      value={buyingFee?.feePolicyType}
                      onChange={(event) => {
                        const feePolicyType = event.target.value
                        const option = buyingFeePolicyList.find((o) => o.feePolicyType === feePolicyType)
                        setBuyingFee({
                          amount: option?.amount || (0 as number),
                          feePolicyType: option?.feePolicyType || ("직접 입력" as string),
                        })
                      }}
                      size="small"
                      fullWidth
                    >
                      {buyingFeePolicyList.map((buyingFeePolicy) => (
                        <MenuItem value={buyingFeePolicy.feePolicyType} key={buyingFeePolicy.feePolicyType}>
                          {buyingFeePolicy.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </td>
                  <td colSpan={2}>
                    <TextField
                      fullWidth
                      placeholder={"0"}
                      size={"small"}
                      value={buyingFee?.amount}
                      inputProps={{
                        style: {
                          textAlign: "right",
                        },
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">원</InputAdornment>,
                      }}
                      onChange={(event) => {
                        setBuyingFee({
                          amount: parseInt(event.target.value.replace(/[^0-9]/g, "")),
                          feePolicyType: "직접 입력",
                        })
                      }}
                      disabled={buyingFee?.feePolicyType != "직접 입력"}
                    />
                  </td>

                  <td className={"header"} width={128}>
                    Tax Free
                    <br />
                    환급 신청
                  </td>
                  <td>
                    <Select
                      required
                      value={taxFreeRefund}
                      onChange={(event) => {
                        setTaxFreeRefund(event.target.value as boolean)
                      }}
                      size="small"
                      fullWidth
                      disabled={onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.NOT_VIVER}
                    >
                      <MenuItem value={true as any}>발급</MenuItem>
                      <MenuItem value={false as any}>미발급</MenuItem>
                    </Select>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={"basic"} style={{ marginTop: 16 }}>
              <tbody>
                <tr>
                  <td className={"header"} width={128} rowSpan={5 + (onSitePayments.length ?? 1 - 1)}>
                    총 결제금액
                  </td>
                  <td className={"header"} width={128}>
                    구매가
                  </td>
                  <td>
                    <BoxHorizontal justifyContent="space-between" paddingHorizontal={12}>
                      <BoxHorizontal>
                        <div></div>
                      </BoxHorizontal>
                      <BoxHorizontal>
                        <div>{numberWithCommas(product.price ?? 0)}원</div>
                      </BoxHorizontal>
                    </BoxHorizontal>
                  </td>
                </tr>
                <tr>
                  <td className={"header"} width={128}>
                    진단비
                  </td>
                  <td>
                    <BoxHorizontal justifyContent="space-between" paddingHorizontal={12}>
                      <BoxHorizontal>
                        <div>
                          {diagnosisType}
                          {`(${onSiteIsDiagnosisFee ? "유료" : "무료"})`}
                        </div>
                      </BoxHorizontal>
                      <BoxHorizontal>
                        <div>
                          {numberWithCommas(
                            !onSiteIsDiagnosisFee
                              ? 0
                              : diagnosisType === "일반 감정진단"
                                ? diagnosisFee.basic
                                : diagnosisFee.advanced
                          )}
                          원
                        </div>
                      </BoxHorizontal>
                    </BoxHorizontal>
                  </td>
                </tr>
                <tr>
                  <td className={"header"} width={128}>
                    거래수수료
                  </td>
                  <td>
                    <BoxHorizontal justifyContent="space-between" paddingHorizontal={12}>
                      <BoxHorizontal>
                        <div>{buyingFee?.feePolicyType}</div>
                      </BoxHorizontal>
                      <BoxHorizontal>
                        <div>{numberWithCommas(buyingFee?.amount)}원</div>
                      </BoxHorizontal>
                    </BoxHorizontal>
                  </td>
                </tr>
                {onSitePaymentMode === "단일결제" && (
                  <tr>
                    <td className={"header"} width={128}>
                      결제 내역
                    </td>
                    <td>
                      <BoxHorizontal justifyContent="space-between" paddingHorizontal={12}>
                        <BoxHorizontal>
                          <div>
                            {onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.EASY_PAY
                              ? `간편결제(${onSitePaymentInputMethod}_${onSiteIsCashReceipt ? "YES" : "NO"})`
                              : onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.CARD
                                ? `${onSitePaymentInputMethod}(${onSitePaymentCardCompany ? onSitePaymentCardCompany.label : ""}_${onSiteIsCashReceipt ? "YES" : "NO"})`
                                : onSitePaymentMethodTypeGroup === PaymentMethodTypeGroupCode.NOT_VIVER
                                  ? `타처결제(${onSitePaymentInputMethod}_${onSiteIsCashReceipt ? "YES" : "NO"})`
                                  : `${onSitePaymentInputMethod}(${onSiteIsCashReceipt ? "YES" : "NO"})`}
                          </div>
                        </BoxHorizontal>
                        <BoxHorizontal>
                          <div>{numberWithCommas(onSiteSellPaymentAmountTotal)}원</div>
                        </BoxHorizontal>
                      </BoxHorizontal>
                    </td>
                  </tr>
                )}
                {onSitePaymentMode === "분할결제" ? (
                  onSitePayments.length ? (
                    onSitePayments.map((payment, index) => (
                      <tr key={index}>
                        {index === 0 && (
                          <>
                            <td className={"header"} width={128} rowSpan={onSitePayments.length}>
                              결제 내역
                            </td>
                          </>
                        )}
                        <td>
                          <BoxHorizontal justifyContent="space-between" paddingHorizontal={12}>
                            {/* mock */}
                            <BoxHorizontal>
                              <div>
                                {payment.paymentMethodTypeGroup === PaymentMethodTypeGroupCode.EASY_PAY
                                  ? `간편결제(${payment.paymentMethodType}_${payment.cashReceipt ? "YES" : "NO"})`
                                  : payment.paymentMethodTypeGroup === PaymentMethodTypeGroupCode.CARD
                                    ? `${payment.paymentMethodType}(${payment.cardCompany ? payment.cardCompany.label : null}_${payment.cashReceipt ? "YES" : "NO"})`
                                    : payment.paymentMethodTypeGroup === PaymentMethodTypeGroupCode.NOT_VIVER
                                      ? `타처결제(${payment.paymentMethodType}_${payment.cashReceipt ? "YES" : "NO"})`
                                      : `${payment.paymentMethodType}(${payment.cashReceipt ? "YES" : "NO"})`}
                                {`${payment.diagnosisFeePayment ? "진단비" : ""}`}
                              </div>
                            </BoxHorizontal>
                            <BoxHorizontal>
                              <div>{numberWithCommas(payment.paymentAmount)}원</div>
                            </BoxHorizontal>
                          </BoxHorizontal>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className={"header"} width={128}>
                        결제 내역
                      </td>
                      <td>
                        <BoxHorizontal justifyContent="space-between" paddingHorizontal={12}>
                          {/* mock */}
                          <BoxHorizontal>
                            <div>-</div>
                          </BoxHorizontal>
                          <BoxHorizontal>
                            <div>-</div>
                          </BoxHorizontal>
                        </BoxHorizontal>
                      </td>
                    </tr>
                  )
                ) : null}
                <tr>
                  <td className={"header"} width={128}>
                    합계
                  </td>
                  <td>
                    <BoxHorizontal justifyContent="flex-end" paddingHorizontal={12}>
                      <BoxHorizontal>
                        <div>
                          {numberWithCommas(onSiteSellPaymentAmountTotal)}원
                          {onSiteSellFinalPrice !== onSiteSellPaymentAmountTotal
                            ? ` (${numberWithCommas(Math.abs(onSiteSellPaymentAmountTotal - onSiteSellFinalPrice))}원 ${
                                onSiteSellPaymentAmountTotal - onSiteSellFinalPrice < 0 ? "부족" : "초과"
                              })`
                            : null}
                        </div>
                      </BoxHorizontal>
                    </BoxHorizontal>
                  </td>
                </tr>
              </tbody>
            </table>

            <BoxHorizontal justifyContent="flex-end" paddingTop={16}>
              {sellOrder?.product.status === ProductStatusCode.판매_완료 &&
                sellOrder?.product.saleType === "직접판매" && (
                  <Button
                    style={{ marginRight: 8 }}
                    variant={"contained"}
                    color={"error"}
                    onClick={onPressRefundOnShowRoom}
                  >
                    쇼룸 환불 처리
                  </Button>
                )}
              <Button
                variant={"contained"}
                color={"success"}
                onClick={onPressSellOnShowRoom}
                disabled={
                  !(
                    sellOrder?.product.status === ProductStatusCode.판매중 &&
                    onSiteSellFinalPrice === onSiteSellPaymentAmountTotal
                  )
                }
              >
                쇼룸 판매 처리
              </Button>
            </BoxHorizontal>
          </>
        )}
        <h2>상품상태</h2>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td className={"header"} width={"15%"}>
                시리얼 넘버
              </td>
              <td className={"header"} width={"15%"}>
                제품상태
              </td>
              <td className={"header"} width={"15%"}>
                보증서
              </td>
              <td className={"header"} width={"15%"}>
                정품박스
              </td>
              <td className={"header"} width={"20%"}>
                부속품
              </td>
              <td className={"header"}>스탬핑 일자</td>
            </tr>
            <tr>
              <td>
                {isProductInformationEditable ? (
                  <div style={{ margin: "0 10px" }}>
                    <SerialNumberFinder
                      value={product?.serialNo || ""}
                      onChangeValue={(value) => setProduct({ ...product, serialNo: value })}
                      onSelect={(serial) => setProduct({ ...product, serialNo: serial?.serialNo })}
                    />
                  </div>
                ) : (
                  product?.serialNo
                )}
              </td>
              <td>
                <RadioGroup
                  row
                  sx={{ justifyContent: "center" }}
                  onChange={(event, value) => {
                    if (!isProductInformationEditable) return

                    const targetValue = event.target.value as ProductConditionCode
                    const newExposedProductInfo = { ...product.exposedProductInfo, productCondition: targetValue }

                    setProduct({ ...product, exposedProductInfo: newExposedProductInfo })
                    getCurrentResellPrice({ ...product, exposedProductInfo: newExposedProductInfo })
                  }}
                >
                  <FormControlLabel
                    value="미착용"
                    label="미착용"
                    checked={product.exposedProductInfo?.productCondition === ProductConditionCode.미착용}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value="중고"
                    label="중고"
                    checked={product.exposedProductInfo?.productCondition === ProductConditionCode.중고}
                    control={<Radio />}
                  />
                </RadioGroup>
              </td>
              <td>
                <RadioGroup
                  row
                  sx={{ justifyContent: "center" }}
                  onChange={(event) => {
                    if (!isProductInformationEditable) return

                    const targetValue = event.target.value as OfficialGuaranteeCode

                    setProduct({
                      ...product,
                      exposedProductInfo: {
                        ...product.exposedProductInfo,
                        officialGuarantee: targetValue,
                      },
                    })
                    getCurrentResellPrice({
                      ...product,
                      exposedProductInfo: { ...product.exposedProductInfo, officialGuarantee: targetValue },
                    })
                  }}
                >
                  <FormControlLabel
                    value="있음"
                    label="있음"
                    checked={product.exposedProductInfo?.officialGuarantee === OfficialGuaranteeCode.있음}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value="없음"
                    label="없음"
                    checked={product.exposedProductInfo?.officialGuarantee === OfficialGuaranteeCode.없음}
                    control={<Radio />}
                  />
                </RadioGroup>
              </td>
              <td>
                <RadioGroup
                  row
                  sx={{ justifyContent: "center" }}
                  onChange={(event, value) => {
                    if (!isProductInformationEditable) return

                    const targetValue = event.target.value as OriginalBoxConditionCode

                    setProduct({
                      ...product,
                      exposedProductInfo: {
                        ...product.exposedProductInfo,
                        originalBoxCondition: targetValue,
                      },
                    })
                    getCurrentResellPrice({
                      ...product,
                      exposedProductInfo: {
                        ...product.exposedProductInfo,
                      },
                    })
                  }}
                >
                  <FormControlLabel
                    value={OriginalBoxConditionCode.있음}
                    label="있음"
                    checked={product.exposedProductInfo?.originalBoxCondition === OriginalBoxConditionCode.있음}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value={OriginalBoxConditionCode.없음}
                    label="없음"
                    checked={product.exposedProductInfo?.originalBoxCondition === OriginalBoxConditionCode.없음}
                    control={<Radio />}
                  />
                </RadioGroup>
              </td>
              <td>
                <RadioGroup
                  row
                  sx={{ justifyContent: "center" }}
                  onChange={(event, value) => {
                    if (!isProductInformationEditable) return

                    const targetValue = event.target.value as AccessoriesConditionCode

                    const accessories = JSON.parse(JSON.stringify(product.exposedProductInfo?.accessories))
                    if (targetValue === AccessoriesConditionCode.있음) {
                      accessories.outerBox = true
                      accessories.innerBox = true
                      accessories.coscCert = true
                      accessories.userManual = true
                      accessories.guaranteeCase = true
                      accessories.medal = true
                    } else if (value === AccessoriesConditionCode.없음) {
                      accessories.outerBox = false
                      accessories.innerBox = false
                      accessories.coscCert = false
                      accessories.userManual = false
                      accessories.guaranteeCase = false
                      accessories.medal = false
                    }

                    setProduct({
                      ...product,
                      exposedProductInfo: {
                        ...product.exposedProductInfo,
                        accessoriesCondition: targetValue,
                        accessories: accessories,
                      },
                    })
                    getCurrentResellPrice({
                      ...product,
                      exposedProductInfo: {
                        ...product.exposedProductInfo,
                        accessoriesCondition: targetValue,
                        accessories: accessories,
                      },
                    })
                  }}
                >
                  <FormControlLabel
                    value="있음"
                    label="있음"
                    checked={product.exposedProductInfo?.accessoriesCondition === AccessoriesConditionCode.있음}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value="없음"
                    label="없음"
                    checked={product.exposedProductInfo?.accessoriesCondition === AccessoriesConditionCode.없음}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value="일부파손"
                    label="일부파손"
                    checked={product.exposedProductInfo?.accessoriesCondition === AccessoriesConditionCode.일부파손}
                    control={<Radio />}
                  />
                </RadioGroup>
              </td>
              <td>
                {/*<TextField
                  variant="standard"
                  type={"date"}
                  value={product.exposedProductInfo?.stampingDate || ""}
                  onChange={(event) => {
                    if (!isProductInformationEditable) return

                    setProduct({
                      ...product,
                      exposedProductInfo: { ...product.exposedProductInfo, stampingDate: event.target.value },
                    })
                    getCurrentResellPrice({
                      ...product,
                      exposedProductInfo: { ...product.exposedProductInfo, stampingDate: event.target.value },
                    })
                  }}
                  onWheel={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    return
                  }}
                  onKeyDown={(event) => {
                    if (/\d/.test(event.key)) return
                    if ("Backspace" === event.key || "Delete" === event.key) return
                    event.preventDefault()
                  }}
                />*/}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  <TextField
                    variant="standard"
                    style={{ width: "50%" }}
                    inputProps={{ maxLength: 10 }}
                    type={"text"}
                    placeholder={"DD/MM/YYYY"}
                    disabled={product.exposedProductInfo?.officialGuarantee == "없음"}
                    value={formattedDate}
                    onChange={(event) => {
                      if (!isProductInformationEditable) return

                      setProduct({
                        ...product,
                        exposedProductInfo: { ...product.exposedProductInfo, stampingDate: event.target.value },
                      })
                      getCurrentResellPrice({
                        ...product,
                        exposedProductInfo: { ...product.exposedProductInfo, stampingDate: event.target.value },
                      })
                      setFormattedDate(event.target.value)
                    }}
                  />
                  <FormControlLabel
                    value={"연도만 표기"}
                    label={"연도만 표기"}
                    disabled={product.exposedProductInfo?.officialGuarantee == "없음"}
                    checked={!product.exposedProductInfo?.displayStampingMonth ?? false}
                    control={<Checkbox />}
                    onChange={(_, value) => {
                      if (!isProductInformationEditable) return

                      setProduct({
                        ...product,
                        exposedProductInfo: {
                          ...product.exposedProductInfo,
                          displayStampingMonth: !value,
                        },
                      })
                    }}
                  />
                </div>
                <RadioGroup row sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <Typography style={{ marginLeft: 10 }}>정식 A/S기간&nbsp;</Typography>
                  <div style={{ marginRight: 10 }}>
                    <FormControlLabel
                      label="내"
                      checked={
                        formattedDate
                          ? DateTime.fromFormat(formattedDate, "dd/MM/yyyy").diffNow("years").years >= -5
                          : false
                      }
                      control={<Radio color={"default"} />}
                    />
                    <FormControlLabel
                      label="외"
                      checked={
                        formattedDate
                          ? DateTime.fromFormat(formattedDate, "dd/MM/yyyy").diffNow("years").years < -5
                          : false
                      }
                      control={<Radio color={"default"} />}
                    />
                  </div>
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td className={"header"} colSpan={4}>
                상세 부속품 체크리스트
                <br />
                <span style={{ fontWeight: 400, fontSize: "10pt", color: "#777" }}>(있는 부속품만 체크해주세요)</span>
              </td>
              <td className={"header"} colSpan={2}>
                특이사항
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                {brand?.accessories?.map(({ value, key }) => (
                  <FormControlLabel
                    label={value}
                    checked={!!product.exposedProductInfo?.accessories && product.exposedProductInfo?.accessories[key]}
                    control={<Checkbox />}
                    onChange={(event, value) => {
                      if (!isProductInformationEditable) return

                      const productAccessories: Accessories = product.exposedProductInfo?.accessories || {}
                      let accessoryCondition: AccessoriesConditionCode =
                        product.exposedProductInfo?.accessoriesCondition || AccessoriesConditionCode.없음
                      productAccessories[key] = value

                      setProduct({
                        ...product,
                        exposedProductInfo: {
                          ...product.exposedProductInfo,
                          accessoriesCondition: accessoryCondition,
                          accessories: productAccessories,
                        },
                      })
                    }}
                  />
                ))}
              </td>
              <td colSpan={2}>
                <TextField
                  variant="standard"
                  type={"text"}
                  fullWidth={true}
                  multiline={true}
                  value={product.exposedProductInfo?.accessories?.note || ""}
                  disabled={!isProductInformationEditable}
                  onChange={(event) => {
                    const accessories = JSON.parse(JSON.stringify(product.exposedProductInfo?.accessories))
                    accessories.note = event.target.value

                    setProduct({
                      ...product,
                      exposedProductInfo: { ...product.exposedProductInfo, accessories: accessories },
                    })
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <h3>(중고) 추가정보 - 상품 컨디션 총평 작성용</h3>
        <table
          className={
            "basic" + (product.exposedProductInfo?.productCondition === ProductConditionCode.미착용 ? " dim" : "")
          }
        >
          <tbody>
            <tr>
              <td className={"header"}>업그레이드 진행 여부</td>
              <td colSpan={5}>
                {product?.upgradeRequired?.map((it, idx) => (
                  <FormControlLabel
                    label={it.label}
                    checked={product?.upgradeRequired?.[idx]?.action}
                    disabled={product.exposedProductInfo?.productCondition === ProductConditionCode.미착용}
                    control={<Checkbox />}
                    onChange={(event, value) => {
                      const upgradeRequired = JSON.parse(JSON.stringify(product?.upgradeRequired))
                      upgradeRequired[idx]!.action = value
                      setProduct({ ...product, upgradeRequired: upgradeRequired })
                    }}
                  />
                ))}
              </td>
            </tr>
            <tr>
              <td className={"header"}>중고 종합상태</td>
              <td colSpan={5}>
                <RadioGroup
                  row
                  sx={{ justifyContent: "center" }}
                  onChange={(event, value) => {
                    if (!product) return
                    if (value !== UsedConditionLevelCode.새상품_수준 && value !== UsedConditionLevelCode.사용감_있음) {
                      return
                    }

                    setProduct({ ...product, usedCondition: value })
                  }}
                >
                  <FormControlLabel
                    value={"새상품 수준"}
                    label="새상품 수준"
                    checked={product?.usedCondition === UsedConditionLevelCode.새상품_수준}
                    disabled={product.exposedProductInfo?.productCondition === ProductConditionCode.미착용}
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value={"사용감 있음"}
                    label="사용감 있음"
                    checked={product?.usedCondition === UsedConditionLevelCode.사용감_있음}
                    disabled={product.exposedProductInfo?.productCondition === ProductConditionCode.미착용}
                    control={<Radio />}
                  />
                </RadioGroup>
              </td>
            </tr>
            <tr>
              <td className={"header"}>총평 반영 필요 추가 정보</td>
              <td colSpan={5}>
                <TextareaAutosize
                  style={{ width: "100%" }}
                  minRows={3}
                  value={product?.usedConditionNote || ""}
                  disabled={product.exposedProductInfo?.productCondition === ProductConditionCode.미착용}
                  onChange={(event) => {
                    setProduct((prevProduct) => ({ ...prevProduct, usedConditionNote: event.target.value }))
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <h2>상세진단</h2>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>외부 컨디션</AccordionSummary>
          <AccordionDetails>
            <table className={"basic"}>
              <tbody>
                <tr>
                  <td className={"header"}>상세항목</td>
                  <td colSpan={3} className={"header"}>
                    항목별 상태
                  </td>
                  <td className={"header"}>상세설명</td>
                  <td className={"header"}>사진필요</td>
                </tr>
                {product?.appearanceCondition?.parts?.map((it, idx) => (
                  <tr>
                    <td>{it.label}</td>
                    <td colSpan={3}>
                      <RadioGroup
                        row
                        sx={{ justifyContent: "center" }}
                        onChange={(event, value) => {
                          if (isNotEditableUnUsedProduct) {
                            return
                          }

                          const appearanceCondition = JSON.parse(JSON.stringify(product!.appearanceCondition))
                          appearanceCondition!.parts[idx]!.grade = value
                          setProduct({ ...product, appearanceCondition: appearanceCondition })
                        }}
                      >
                        <FormControlLabel
                          value="양호"
                          label="양호"
                          checked={product?.appearanceCondition?.parts[idx]!.grade === DiagnosisGradeCode.양호}
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value="보통"
                          label="보통"
                          checked={product?.appearanceCondition?.parts[idx]!.grade === DiagnosisGradeCode.보통}
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value="업그레이드 필요"
                          label="업그레이드 필요"
                          disabled
                          checked={
                            product?.appearanceCondition?.parts[idx]!.grade === DiagnosisGradeCode.업그레이드_필요
                          }
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value="판매 불가"
                          label="판매 불가"
                          disabled
                          checked={product?.appearanceCondition?.parts[idx]!.grade === DiagnosisGradeCode.판매_불가}
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </td>
                    <td>
                      <TextField
                        variant="standard"
                        fullWidth={true}
                        type={"text"}
                        disabled={isNotEditableUnUsedProduct}
                        multiline={true}
                        value={product?.appearanceCondition?.parts[idx]!.note || ""}
                        onChange={(event) => {
                          const appearanceCondition = JSON.parse(JSON.stringify(product!.appearanceCondition))
                          appearanceCondition!.parts[idx]!.note = event.target.value
                          setProduct({ ...product, appearanceCondition: appearanceCondition })
                        }}
                      />
                    </td>
                    <td>
                      <Checkbox
                        disabled={isNotEditableUnUsedProduct}
                        checked={product?.appearanceCondition?.parts[idx]!.imageRequired}
                        onChange={(event, value) => {
                          const appearanceCondition = JSON.parse(JSON.stringify(product!.appearanceCondition))
                          appearanceCondition!.parts[idx]!.imageRequired = value
                          setProduct({ ...product, appearanceCondition: appearanceCondition })
                        }}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={6} className={"header"}>
                    진단자 메모
                  </td>
                </tr>
                <tr>
                  <td colSpan={6}>
                    <TextareaAutosize
                      style={{ width: "100%" }}
                      minRows={3}
                      value={product?.appearanceCondition?.note || ""}
                      onChange={(event) => {
                        if (!product) return

                        const appearanceCondition = JSON.parse(JSON.stringify(product.appearanceCondition))
                        appearanceCondition!.note = event.target.value

                        setProduct({ ...product, appearanceCondition: appearanceCondition })
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>내부 컨디션</AccordionSummary>
          <AccordionDetails>
            <table className={"basic"}>
              <tbody>
                <tr>
                  <td className={"header"}>상세항목</td>
                  <td colSpan={3} className={"header"}>
                    항목별 상태
                  </td>
                  <td className={"header"}>측정값</td>
                  <td className={"header"}>상세설명</td>
                </tr>
                {product?.internalCondition?.parts?.map((it, idx) => (
                  <tr>
                    <td>{it.label}</td>
                    <td colSpan={3}>
                      <RadioGroup
                        row
                        sx={{ justifyContent: "center" }}
                        onChange={(event, value) => {
                          if (isNotEditableUnUsedProduct) {
                            return
                          }

                          const internalCondition = JSON.parse(JSON.stringify(product!.internalCondition))
                          internalCondition!.parts[idx]!.grade = value
                          setProduct({ ...product, internalCondition: internalCondition })
                        }}
                      >
                        <FormControlLabel
                          value="양호"
                          label="양호"
                          checked={product?.internalCondition?.parts[idx]!.grade === DiagnosisGradeCode.양호}
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value="보통"
                          label="보통"
                          checked={product?.internalCondition?.parts[idx]!.grade === DiagnosisGradeCode.보통}
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value="업그레이드 필요"
                          label="업그레이드 필요"
                          disabled
                          checked={product?.internalCondition?.parts[idx]!.grade === DiagnosisGradeCode.업그레이드_필요}
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value="판매 불가"
                          label="판매 불가"
                          disabled
                          checked={product?.internalCondition?.parts[idx]!.grade === DiagnosisGradeCode.판매_불가}
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </td>
                    <td>
                      <TextField
                        variant="standard"
                        type={"text"}
                        value={product?.internalCondition?.parts[idx]!.measured || ""}
                        onChange={(event) => {
                          const internalCondition = JSON.parse(JSON.stringify(product!.internalCondition))
                          internalCondition!.parts[idx]!.measured = event.target.value
                          setProduct({ ...product, internalCondition: internalCondition })
                        }}
                      />
                    </td>
                    <td>
                      <TextField
                        variant="standard"
                        fullWidth={true}
                        type={"text"}
                        multiline={true}
                        value={product?.internalCondition?.parts[idx]!.note || ""}
                        onChange={(event) => {
                          const internalCondition = JSON.parse(JSON.stringify(product!.internalCondition))
                          internalCondition!.parts[idx]!.note = event.target.value
                          setProduct({ ...product, internalCondition: internalCondition })
                        }}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={6} className={"header"}>
                    진단자 메모
                  </td>
                </tr>
                <tr>
                  <td colSpan={6}>
                    <TextareaAutosize
                      style={{ width: "100%" }}
                      minRows={3}
                      value={product?.internalCondition?.note || ""}
                      onChange={(event) => {
                        if (!product) return

                        const internalCondition = JSON.parse(JSON.stringify(product.internalCondition))
                        internalCondition!.note = event.target.value

                        setProduct({ ...product, internalCondition: internalCondition })
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </AccordionDetails>
        </Accordion>
        <h2>상품 동영상 관리</h2>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>YouTube URL</AccordionSummary>
          <AccordionDetails>
            <BoxHorizontal>
              <TextField
                variant="standard"
                type={"text"}
                fullWidth
                value={productEmbeddedVideoUrl}
                onChange={(event) => {
                  setProductEmbeddedVideoUrl(event.target.value)
                }}
              />
              <BoxHorizontal width={20}>{null}</BoxHorizontal>
              <Button
                variant={"contained"}
                color={"success"}
                disabled={product?.embeddedVideoUrl === productEmbeddedVideoUrl}
                onClick={onPressSubmitProductEmbeddedVideoUrl}
              >
                수정
              </Button>
            </BoxHorizontal>
          </AccordionDetails>
        </Accordion>
        <h2>상품 이미지</h2>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>첨부 이미지 (최대 20장)</AccordionSummary>
          <AccordionDetails>
            <AttachmentImageList
              init={product?.images}
              max={20}
              checkInput={false}
              defaultImageLabels={defaultImageLabels}
              save={(images: AttachmentType[]) => {
                if (!product) return

                setProduct({ ...product, images: images })
              }}
            />
          </AccordionDetails>
        </Accordion>
        <h2>상품 가격 정보</h2>
        <h3>바이버 인덱스(시세)</h3>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td className={"header"} colSpan={1}>
                현재 시장 가격범위 (감가 미적용)
              </td>
              <td className={"ta-center"} colSpan={1}>{`${toRange(
                estimatedPrice?.marketPrice?.min,
                estimatedPrice?.marketPrice?.max,
                4,
                true
              )} 만원`}</td>
              <td className={"header"} colSpan={1}>
                최근 30일간 시세 볼륨
              </td>
              <td className={"ta-center"} colSpan={1}>
                {estimatedPrice.marketVolume}
              </td>
            </tr>
            <tr>
              <td className={"header"} colSpan={1}>
                판매신청 당시 예상 가격범위 ({toDateStr(sellOrder?.acceptedAt)})
              </td>
              <td className={"ta-center"} colSpan={1}>{`${toRange(
                sellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtOrder?.min,
                sellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtOrder?.max,
                4
              )} 만원`}</td>
              <td className={"header"} colSpan={1}>
                페널티 기준가
              </td>
              <td className={"ta-center"} colSpan={1}>{`${truncateWithCommas(product?.initialPrice, 4)} 만원`}</td>
            </tr>
            <tr>
              <td className={"header"} colSpan={1}>
                진단확정 당시 예상 가격범위 ({toDateStr(sellOrder?.completeDiagnosisAt)})
              </td>
              <td className={"ta-center"} colSpan={1}>{`${toRange(
                sellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtDiagnosis?.min,
                sellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtDiagnosis?.max,
                4,
                true
              )} 만원`}</td>
              <td className={"header"}>페널티 금액</td>
              <td>{numberWithCommas(penalty) || "-"} 원</td>
            </tr>
            <tr>
              <td className={"header"} colSpan={1}>
                현재 예상 가격범위
              </td>
              <td className={"ta-center"} colSpan={1}>{`${toRange(
                estimatedPrice?.correctedEstimatedPrice?.min,
                estimatedPrice?.correctedEstimatedPrice?.max,
                4
              )} 만원`}</td>
            </tr>
          </tbody>
        </table>
        <h3>상점 노출 가격</h3>
        <table className={"basic"}>
          <tbody>
            <tr>
              <td className={"header"}>최적 판매가</td>
              <td colSpan={3}>
                {sellOrder?.productDiagnosis?.viverPrices?.finalPrice
                  ? truncateWithCommas(sellOrder?.productDiagnosis?.viverPrices?.finalPrice, 4) + " 만원"
                  : "-"}
              </td>
            </tr>
            <tr>
              <td className={"header"}>최종 구매가 / 최적 판매가 (%)</td>
              <td>
                {productPrice && sellOrder?.productDiagnosis?.viverPrices?.finalPrice
                  ? ((1 - productPrice / (sellOrder?.productDiagnosis?.viverPrices?.finalPrice || 1)) * 100).toFixed(2)
                  : "-"}{" "}
                %
              </td>
              <td className={"header"}>최종 구매가 (상점 노출가)</td>
              <td>
                {editing ? (
                  <TextField
                    size={"small"}
                    inputMode={"numeric"}
                    value={productPrice || ""}
                    onChange={(event) => {
                      const numberValue = Number(event.target.value)
                      if (isNaN(numberValue)) {
                        return
                      }

                      setProductPrice(numberValue)
                    }}
                    onWheel={(event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      return
                    }}
                    onKeyDown={(event) => {
                      if (/\d/.test(event.key)) return
                      if ("Backspace" === event.key || "Delete" === event.key) return
                      event.preventDefault()
                    }}
                  />
                ) : (
                  truncateWithCommas(parseInt((product?.price || 0).toString()), 4) + " 만원"
                )}
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant={editing ? "contained" : "outlined"}
                  size={"small"}
                  color={"error"}
                  disabled={
                    sellOrder?.sellOrderStatus !== SellOrderStatusCode.진단_완료 &&
                    sellOrder?.sellOrderStatus !== SellOrderStatusCode.판매_대기 &&
                    sellOrder?.sellOrderStatus !== SellOrderStatusCode.판매중
                  }
                  onClick={() => {
                    setEditing(!editing)

                    if (editing) changeProductPrice()
                  }}
                >
                  {editing ? "확인" : "수정"}
                </Button>
                <Button
                  variant={editing ? "contained" : "outlined"}
                  size={"small"}
                  color={"info"}
                  onClick={() => setPriceHistoryPopup(true)}
                >
                  변경이력
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        {product?.saleType !== "진단전판매" && (
          <>
            <h2>매입보증 정보</h2>
            <ReSellGuaranteedOption
              toggleReSellGuaranteeOption={toggleReSellGuaranteeOption}
              changeProductGuaranteeState={changeProductGuaranteeState}
              repurchaseGuarantee={product?.repurchaseGuarantee}
              repurchaseGuaranteePeriod={product?.repurchaseGuaranteePeriod}
              repurchaseGuaranteeRate={product?.repurchaseGuaranteeRate}
            />
          </>
        )}
        <h2>총평</h2>
        <TextareaAutosize
          minRows={5}
          style={{ width: "100%" }}
          value={product?.generalReview}
          onChange={(event) => {
            if (!product) return

            setProduct({ ...product, generalReview: event.target.value })
          }}
        />
        <Button
          size={"medium"}
          disabled={
            sellOrder?.sellOrderStatus !== SellOrderStatusCode.진단_완료 &&
            sellOrder?.sellOrderStatus !== SellOrderStatusCode.판매_대기 &&
            sellOrder?.sellOrderStatus !== SellOrderStatusCode.판매중
          }
          variant={"outlined"}
          onClick={() => generateGeneralReview(product!!)}
        >
          자동 작성
        </Button>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} disabled={isEditingDisabled} onClick={() => update(product!!)}>
          변경내역 저장
        </Button>
        {sellOrder?.sellOrderStatus === SellOrderStatusCode.판매_대기 && (
          <Button variant={"contained"} color={"error"} onClick={() => finishProductSetUp()}>
            상품화 완료
          </Button>
        )}
        &nbsp;
        <Button color={"error"} onClick={() => handleClose()}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditPreProductPopup
